import { ComponentType } from "react";
import loggedInWrapper from "../hoc/loggedInWrapper";
import requirePermission from "../hoc/requirePermission";
import AppConfigPage from "../pages/AppConfigPage";
import ApplicantPage from "../pages/ApplicantPage";
import ApplicationListPage from "../pages/ApplicationListPage";
import ReportingGroupEditorPage from "../pages/ReportingGroupEditorPage";
import ReportingGroupListPage from "../pages/ReportingGroupListPage";
import LabelPrototypeEditorPage from "../pages/LabelPrototypeEditorPage";
import LabelPrototypeListPage from "../pages/LabelPrototypeListPage";
import MyAccountPage from "../pages/MyAccountPage/MyAccountPage";
import InternationalEquivsPage from "../pages/InternationalEquivs";
import PredictionsPage from "../pages/PredictionsPage/index";
import SncPage from "../pages/SncPage";
import ProgrammeEditorPage from "../pages/ProgrammeEditorPage";
import ProgrammeListPage from "../pages/ProgrammeListPage";
import SchoolConfigPage from "../pages/SchoolConfigPage";
import SelectorListPage from "../pages/SelectorListPage";
import UserEditorPage from "../pages/UserEditorPage";
import BulkUserEditorPage from "../pages/BulkUserEditorPage";
import BulkImportClearingPlusApplicants from "../pages/BulkImportClearingPlusApplicants";
import UserListPage from "../pages/UserListPage";
import RoleListPage from "../pages/RoleListPage";
import CreateAnnouncementPage from "../pages/CreateAnnouncementPage";
import AnnouncementsPage from "../pages/AnnouncementsPage";
import RoleEditorPage from "../pages/RoleEditorPage";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Route<A = any> {
  // FIXME: the any here masks a host of type errors
  path: string;
  exact?: boolean;
  component: ComponentType<A>;
}

const routes: Route[] = [
  {
    path: "/",
    component: ApplicationListPage,
    exact: true,
  },
  {
    path: "/applicant",
    component: ApplicantPage,
  },
  {
    path: "/predictions",
    component: PredictionsPage,
    exact: true,
  },
  {
    path: "/status",
    component: SncPage,
    exact: true,
  },
  {
    path: "/admin/role",
    component: requirePermission<{}>(
      RoleListPage,
      (permissions, team) => permissions.canUpdateUsers(team), // Assumption: user managers can also manage roles
    ),
    exact: true,
  },
  {
    path: "/admin/role/new",
    component: requirePermission(RoleEditorPage, (permissions, team) => permissions.canUpdateUsers(team)),
  },
  {
    path: "/admin/role/:id",
    component: requirePermission(RoleEditorPage, (permissions, team) => permissions.canUpdateUsers(team)),
  },
  {
    path: "/admin/user",
    component: requirePermission<{}>(UserListPage, (permissions, team) => permissions.canUpdateUsers(team)),
    exact: true,
  },
  {
    path: "/admin/user/new",
    component: requirePermission(UserEditorPage, (permissions, team) => permissions.canUpdateUsers(team)),
  },
  {
    path: "/admin/add-user-bulk",
    component: requirePermission(BulkUserEditorPage, (permissions, team) => permissions.canUpdateUsers(team)),
  },
  {
    path: "/admin/user/:id",
    component: requirePermission(UserEditorPage, (permissions, team) => permissions.canUpdateUsers(team)),
  },
  {
    path: "/admin/programme",
    component: requirePermission(ProgrammeListPage, (permissions, team) => permissions.canUpdateProgrammes(team)),
    exact: true,
  },
  {
    path: "/admin/programme/new",
    component: requirePermission(ProgrammeEditorPage, (permissions, team) => permissions.canUpdateProgrammes(team)),
  },
  {
    path: "/admin/programme/:code",
    component: requirePermission(ProgrammeEditorPage, (permissions, team) => permissions.canUpdateProgrammes(team)),
  },
  {
    path: "/admin/reporting-group",
    component: requirePermission(ReportingGroupListPage, (permissions, team) => permissions.canUpdateReportingGroups(team)),
    exact: true,
  },
  {
    path: "/admin/reporting-group/new",
    component: requirePermission(ReportingGroupEditorPage, (permissions, team) => permissions.canUpdateReportingGroups(team)),
  },
  {
    path: "/admin/reporting-group/:id",
    component: requirePermission(ReportingGroupEditorPage, (permissions, team) => permissions.canUpdateReportingGroups(team)),
  },
  {
    path: "/admin/label",
    component: requirePermission(LabelPrototypeListPage, (permissions, team) => permissions.canUpdateLabelPrototypes(team)),
    exact: true,
  },
  {
    path: "/admin/label/new",
    component: requirePermission(LabelPrototypeEditorPage, (permissions, team) => permissions.canUpdateLabelPrototypes(team)),
  },
  {
    path: "/admin/label/:id",
    component: requirePermission(LabelPrototypeEditorPage, (permissions, team) => permissions.canUpdateLabelPrototypes(team)),
  },
  {
    path: "/admin/settings",
    component: requirePermission(
      SchoolConfigPage,
      (permissions, team) =>
        permissions.canUpdateSchoolConfig(team) ||
        permissions.canUpdateSchoolDeadlines(team) ||
        permissions.canUpdateTelephoneScripts(team) ||
        permissions.canUpdateEmailTemplates(team) ||
        permissions.canUpdateClassifierRules(team),
    ),
  },
  {
    path: "/admin/selector-list",
    component: requirePermission(SelectorListPage, (permissions, team) => permissions.canSyncSelectorList(team)),
  },
  {
    path: "/admin/international-equivs",
    component: requirePermission(InternationalEquivsPage, (permissions, _team) => permissions.canViewInternationalEquivsTable()),
  },
  {
    path: "/admin/app-config",
    component: requirePermission(AppConfigPage, (permissions, team) => permissions.canViewAppConfig(team)),
  },
  {
    path: "/admin/create-announcement",
    component: requirePermission(CreateAnnouncementPage, permissions => permissions.canAddAnnouncements()),
  },
  {
    path: "/admin/bulk-upload-applicants",
    component: requirePermission(BulkImportClearingPlusApplicants, permissions => permissions.canImportUcasClearingPlusApplicants()),
  },
  {
    path: "/announcements",
    component: AnnouncementsPage,
  },
  {
    path: "/account",
    component: MyAccountPage,
  },
];

export default routes.map(route => ({
  ...route,
  component: loggedInWrapper(route.component),
}));
