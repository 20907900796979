import React from "react";
import { Button, Container, Grid, Header } from "semantic-ui-react";
import type { User } from "../../model/types";
import Title from "../../components/Title";
import { Opt } from "../../utils";
import { ActAsForm } from "@qmspringboard/app/src/pages/MyAccountPage/ActAsForm";
import { UserView } from "@qmspringboard/app/src/pages/MyAccountPage/UserView";
import { useActAs, useStopActingAs, useWhoAmI } from "@qmspringboard/app/src/hooks/useWhoAmI";

const renderLoggedInUser = (loggedInUser: Opt<User>) => {
  return (
    loggedInUser && (
      <Container>
        <Header as="h3">Logged in as</Header>
        <UserView user={loggedInUser} />
      </Container>
    )
  );
};

const renderActingAsUser = (actingAsUser: User) => {
  return (
    <Container>
      <Header as="h3">Acting as</Header>
      <UserView user={actingAsUser} />
    </Container>
  );
};

const renderRefreshForm = (loggedInUser: Opt<User>, refresh: () => void) => {
  return (
    loggedInUser && (
      <Container>
        <Header as="h3">Reload permissions</Header>

        <p>Click the button below if you need to reload your permissions after they have been changed by an administrator.</p>

        <Button onClick={() => refresh()}>Reload permissions</Button>
      </Container>
    )
  );
};

const MyAccountPage = () => {
  const refresh = () => null;

  const { data: whoAmIData, isLoading } = useWhoAmI();
  const useAckAsHandler = useActAs();
  const useUnactAsHandler = useStopActingAs();

  if (isLoading || !whoAmIData) {
    return <>Loading...</>;
  }

  const { user: loggedInUser, actingAs: actingAsUser } = whoAmIData;

  return (
    <Title title="My Account">
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width="8">{renderRefreshForm(loggedInUser, refresh)}</Grid.Column>
            <Grid.Column width="8">
              <ActAsForm
                loggedInUser={loggedInUser}
                actingAsUser={actingAsUser}
                handleActAsClick={useAckAsHandler}
                handleUnactAsClick={useUnactAsHandler}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">{renderLoggedInUser(loggedInUser)}</Grid.Column>
            <Grid.Column width="8">{actingAsUser && renderActingAsUser(actingAsUser)}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Title>
  );
};

export default MyAccountPage;
