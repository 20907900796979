import { Message, Role } from "../../model/types";

export type MyState = {
  fetching: boolean;
  fetched: boolean;
  role: Role;
  clientMessages: Message[];
  serverMessages: Message[];
};

export const emptyRole = (): Role => ({
  name: "",
  permissions: [],
  id: -1,
});

export const initialState = (): MyState => {
  const role = emptyRole();

  return {
    fetching: false,
    fetched: false,
    role: role,
    clientMessages: [],
    serverMessages: [],
  };
};
