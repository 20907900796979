import { Location } from "history";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Dropdown, Grid, Segment } from "semantic-ui-react";
import * as pot from "../api/pot";
import ApplicationList from "../components/ApplicationList";
import { FieldLabel } from "../components/fields";
import Pager from "../components/Pager";
import SearchField from "../components/SearchField";
import SearchHelp from "../components/SearchHelp";
import Title from "../components/Title";
import { ApplicationSearchKeywordEnum } from "../model/enums";
import { emptySearchResults, SearchParams } from "../model/search";
import { ApplicationSummary, SearchResults } from "../model/types";
import * as applicationSearch from "../reducers/applicationSearch";
import { locationQueryParams } from "../utils/queryParams";
import { Opt, safeParseInt } from "../utils";
import LabelDropdown from "../components/LabelDropdown";
import { PAGE_SIZE } from "../constants";
import usePermissions from "@qmspringboard/app/src/hooks/usePermissions";

type ApplicationListPageProps = {};

type AllApplicationListPageProps = ApplicationListPageProps & {
  location: Location;
  search: (school: Opt<string>, params: SearchParams) => Promise<SearchResults<ApplicationSummary>>;
  reset: () => void;
  results: pot.Pot<SearchResults<ApplicationSummary>>;
  downloadCsvUrl: (query: applicationSearch.DownloadCsvUrlOptions) => string;
};

interface PageIndicatorProps<A> {
  items: A[];
  start: number;
  total: number;
}

function PageIndicator<A>({ items, start, total }: PageIndicatorProps<A>) {
  return (
    <Segment basic vertical textAlign="center">
      {items.length > 0 ? `Showing results ${start + 1} to ${start + items.length} of ${total}` : "No results"}
    </Segment>
  );
}

const ApplicationListPage = (props: AllApplicationListPageProps) => {
  const handleDownloadClick = (options: applicationSearch.DownloadCsvUrlOptions) => {
    const { q, sortby, sortdir } = locationQueryParams(props.location);
    window.location.href = props.downloadCsvUrl({
      q,
      sortby,
      sortdir,
      ...options,
    });
  };

  const pageSize = () => {
    const { count: countString } = locationQueryParams(props.location);
    return safeParseInt(countString, PAGE_SIZE);
  };

  const permissions = usePermissions();
  const { search, reset } = props;

  const results = pot.getOrElse(props.results, emptySearchResults());
  const fetching = pot.fetching(props.results);
  const fetchError = pot.error(props.results);

  const canDownloadCsv = true;
  const canCreateAppn = permissions.canCreateApplicants();

  return (
    <Title title="Applications">
      <Container fluid>
        <Segment padded basic>
          <Grid stackable columns="equal">
            <Grid.Row>
              <Grid.Column>
                <FieldLabel>
                  <SearchField searchBySchool search={search} reset={reset} permissions={permissions} />
                </FieldLabel>
              </Grid.Column>
              <Grid.Column>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <LabelDropdown />
                    </Grid.Column>
                    <Grid.Column>
                      {canDownloadCsv && (
                        <FieldLabel>
                          <Dropdown className="icon" button fluid icon={null} text="Download CSV" style={{ textAlign: "center" }}>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                text={"Without Personal Data"}
                                onClick={() =>
                                  handleDownloadClick({
                                    personal: false,
                                    notes: false,
                                  })
                                }
                              />
                              <Dropdown.Item
                                text={"Including Personal Data"}
                                onClick={() =>
                                  handleDownloadClick({
                                    personal: true,
                                    notes: false,
                                  })
                                }
                              />
                              <Dropdown.Item
                                text={"Including Personal Data and Notes"}
                                onClick={() =>
                                  handleDownloadClick({
                                    personal: true,
                                    notes: true,
                                  })
                                }
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </FieldLabel>
                      )}
                    </Grid.Column>
                    {canCreateAppn && (
                      <Grid.Column>
                        <FieldLabel>
                          <Button fluid primary as={Link} to="/applicant/search">
                            Take a call
                          </Button>
                        </FieldLabel>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <SearchHelp keywords={ApplicationSearchKeywordEnum.entries} />

          {results.items.length > 0 && <PageIndicator items={results.items} start={results.start} total={results.total} />}

          <ApplicationList results={results} fetching={fetching} fetchError={fetchError} />

          <Pager results={props.results} pageSize={pageSize()} />
        </Segment>
      </Container>
    </Title>
  );
};

export default connect(
  state => ({
    results: applicationSearch.results(state),
    fetching: applicationSearch.fetching(state),
    downloadCsvUrl: applicationSearch.downloadCsvUrl(state),
  }),
  {
    search: applicationSearch.search,
    reset: applicationSearch.reset,
  },
)(ApplicationListPage);
