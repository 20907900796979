import { sortBy, without } from "lodash";
import * as api from "../../api";
import * as pot from "../../api/pot";
import { SavedSearch } from "../../model/types";
import { Opt } from "../../utils";
import { AppReducer } from "../actions";
import { fetchWithMutex } from "../fetch";
import { rootSelector as schoolSelector } from "../school";
import { AppState } from "../state";
import { initialState, MyState } from "./state";

///////////////
// Selectors //
///////////////

const rootSelector = (state: AppState): MyState => schoolSelector(state).savedSearches;

export const savedSearches = (state: AppState): pot.Pot<SavedSearch[]> => rootSelector(state).searches;

/////////////
// Actions //
/////////////

const INITIALISE = "savedSearches/INITIALISE";
const LOAD_START = "savedSearches/LOAD_START";
const LOAD_SUCCESS = "savedSearches/LOAD_SUCCESS";
const CREATE_START = "savedSearches/CREATE_START";
const CREATE_SUCCESS = "savedSearches/CREATE_SUCCESS";
const DELETE_START = "savedSearches/DELETE_START";
const DELETE_SUCCESS = "savedSearches/DELETE_SUCCESS";

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case INITIALISE:
      return {
        ...state,
        searches: pot.empty(),
      };

    case LOAD_START:
      return {
        ...state,
        searches: pot.pending(action.requestId),
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        searches: pot.ready(action.savedSearches),
      };

    case CREATE_START: {
      const newSearch = action.savedSearch;
      return {
        ...state,
        searches: pot.map(state.searches, searches => sortBy([...searches, newSearch], search => search.title)),
      };
    }

    case CREATE_SUCCESS:
      return state;

    case DELETE_START: {
      const oldSearch = action.savedSearch;
      return {
        ...state,
        searches: pot.map(state.searches, searches => without(searches, oldSearch)),
      };
    }

    case DELETE_SUCCESS:
      return state;

    default:
      return state;
  }
};

export default reducer;

export function fetchSavedSearches(school?: Opt<string>) {
  return fetchWithMutex<SavedSearch[]>({
    mutex: "fetchSavedSearches",

    request(dispatch, getState) {
      return api.fetchSavedSearches(school);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: LOAD_START, school, requestId });
    },

    success(dispatch, getState, savedSearches) {
      dispatch({ type: LOAD_SUCCESS, savedSearches });
    },
  });
}

export function createSavedSearch(savedSearch: SavedSearch) {
  return fetchWithMutex<SavedSearch>({
    mutex: "createSavedSearch",

    request(dispatch, getState) {
      return api.createSavedSearch(savedSearch);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: CREATE_START, savedSearch, requestId });
    },

    success(dispatch, getState, savedSearch) {
      dispatch({ type: CREATE_SUCCESS, savedSearch });
    },
  });
}

export function deleteSavedSearch(savedSearch: SavedSearch) {
  return fetchWithMutex<void>({
    mutex: "deleteSavedSearch",

    request(dispatch, getState) {
      return api.deleteSavedSearch(savedSearch.id);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: DELETE_START, savedSearch, requestId });
    },

    success(dispatch, getState, _result) {
      dispatch({ type: DELETE_SUCCESS, savedSearch });
    },
  });
}
