import * as api from "../../api";
import * as pot from "../../api/pot";
import { SearchParams } from "../../model/search";
import { ReportingGroup, SearchResults } from "../../model/types";
import { Opt } from "../../utils";
import { AppReducer } from "../actions";
import { fetchWithMutex } from "../fetch";
import { rootSelector as schoolSelector } from "../school";
import { AppState } from "../state";
import { MyAction } from "./actions";
import { initialState, MyState } from "./state";

///////////////
// SELECTORS //
///////////////

export function myState(state: AppState): MyState {
  return schoolSelector(state).reportingGroupSearch;
}

export const results = myState;

export function fetching(state: AppState): boolean {
  return pot.fetching(myState(state));
}

/////////////
// ACTIONS //
/////////////

const FETCHING = "reportingGroupSearch/FETCHING";
const RESET = "reportingGroupSearch/RESET";
const FETCHED = "reportingGroupSearch/FETCHED";
const FAILED = "reportingGroupSearch/FAILED";

/////////////////////
// ACTION CREATORS //
/////////////////////

export function reset(): MyAction {
  return { type: RESET };
}

export function search(school: Opt<string>, params: SearchParams) {
  return fetchWithMutex<SearchResults<ReportingGroup>>({
    mutex: "reportingGroupSearch",

    request(dispatch, getState) {
      return api.searchReportingGroups(school, params);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: FETCHING, requestId });
    },

    success(dispatch, getState, results) {
      dispatch({ type: FETCHED, results });
    },
  });
}

/////////////
// REDUCER //
/////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    case FETCHING:
      return pot.pending(action.requestId);
    case FETCHED:
      return pot.ready(action.results);
    case FAILED:
      return pot.failed(action.error);
    default:
      return state;
  }
};

export default reducer;
