import { Opt } from "@qmspringboard/app/src/utils";
import { TelephoneScript } from "@qmspringboard/app/src/model/types.generated";
import { Application } from "@qmspringboard/shared/src/model/applications.generated";
import { fetchOfferScript } from "@qmspringboard/app/src/api";
import { useQuery } from "react-query";
import { Button, Modal } from "semantic-ui-react";
import ApplicationScript from "@qmspringboard/app/src/components/ApplicationScript";
import React from "react";

type FetchScriptResponse = {
  ok: boolean;
  script?: Opt<TelephoneScript>;
};

export function useApplicationScripts(applications: Application[]): TelephoneScript[] | null {
  function results(): Promise<FetchScriptResponse>[] {
    return applications.map((appn: Application) => {
      try {
        return fetchOfferScript(appn.id).then(script => ({
          ok: true,
          script: script,
        }));
      } catch (error) {
        console.log(error);
        return Promise.resolve({ ok: false });
      }
    });
  }

  const queryParams = {
    queryKey: ["applicationScript", applications],
    queryFn: () => Promise.all(results()),
    staleTime: 60 * 1000,
  };

  const { data } = useQuery(queryParams);

  return data ? data.filter((s): s is { ok: true; script: TelephoneScript } => s.ok).map(s => s.script) : null;
}

interface ApplicationScriptProps {
  applications: Application[];
  onOk: () => void;
}

export const ModalApplicationScript = (props: ApplicationScriptProps) => {
  const { applications, onOk } = props;

  const scripts = useApplicationScripts(applications);

  if (scripts && scripts.length === 0) {
    // There are no scripts to display for whatever reason (e.g. empty application list, all calls
    // to the server failed) so just signal that we're done.
    // We setTimeout here as without it we are seeing a warning about unsaved state in the browser (issue #1721)
    setTimeout(onOk, 2);
    return null;
  } else {
    return (
      <Modal open={true} onClose={onOk} size="small">
        <Modal.Content>{scripts?.map((script, i: number) => <ApplicationScript key={i} script={script} />)}</Modal.Content>
        <Modal.Actions>
          <Button onClick={onOk}>OK</Button>
        </Modal.Actions>
      </Modal>
    );
  }
};
