import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import { ddmmhhmm, formatLondon, parseIso } from "@qmspringboard/shared/dist/utils/date";
import React, { Component } from "react";
import { connectModal } from "redux-modal";
import { Button, Dimmer, Divider, Header, Label, Loader, Modal, Segment } from "semantic-ui-react";
import * as api from "../api";
import { format as formatTrackingNumber } from "../model/trackingNumber";
import {
  ApplicantCreated,
  ApplicantDetailsUpdated,
  ApplicantDTO,
  Application,
  ApplicationCreated,
  ApplicationHandover,
  ApplicationUpdated,
  AttachmentApproved,
  AttachmentDeleted,
  AttachmentRequested,
  AttachmentUnapproved,
  AttachmentUploadedToSITS,
  AuditEvent,
  EmailEvent,
  EmailFailed,
  EmailPending,
  EmailSent,
  LabelDeselected,
  LabelSelected,
  NoteCreated,
  NoteDeleted,
  NoteUpdated,
  PhoneCallAttempted,
  ProgrammeClosed,
  QualificationsUpdated,
  SelectorAction,
  SelectorActionApplied,
  SelectorListDataRow,
  UcasApplicantImported,
} from "../model/types";
import { Opt } from "../utils";
import { ConnectModalProps } from "../utils/modal";
import {
  ApplicantDetailsView,
  ApplicationView,
  AttachmentActionView,
  AttachmentRequestedView,
  AttachmentUploadedToSITSActionView,
  EmailView,
  LabelView,
  NoteView,
  PhoneCallAttemptedDetailsView,
  QualificationsDTOView,
  SelectorListDataRowView,
  UcasImportView,
} from "./DetailsView";

interface Props extends ConnectModalProps {
  applicantId: number;
}

interface State {
  events: (AuditEvent | EmailEvent)[] | null;
}

class ModalAuditTrail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      events: null,
    };
  }

  UNSAFE_componentWillMount() {
    api.fetchAuditEvents(this.props.applicantId).then(events => this.setState({ events }));
  }

  onPrimaryClick = () => {
    this.props.handleHide();
  };

  render() {
    const { show, applicantId } = this.props;
    const { events } = this.state;

    const tracking = formatTrackingNumber(applicantId);

    return (
      <Modal open={show} onClose={this.props.handleHide} size="small">
        <Header icon="info circle" content={`Change History for ${tracking}`} />
        <Modal.Content>
          <Dimmer.Dimmable dimmed={events == null}>
            <Dimmer active={events == null} inverted>
              <Loader>Loading</Loader>
            </Dimmer>

            {events && events.length === 0 && (
              <Segment basic textAlign="center">
                No events - the history is empty!
              </Segment>
            )}

            {events &&
              events.length > 0 &&
              events.map((event, index) => (
                <Segment basic vertical key={index}>
                  <EventView event={event} />
                </Segment>
              ))}
          </Dimmer.Dimmable>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.onPrimaryClick}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connectModal({ name: "auditTrail" })(ModalAuditTrail);

const EventView = ({ event }: { event: AuditEvent | EmailEvent }) => {
  switch (event.type) {
    // AuditEvents have a type on the action field:
    case "AuditEvent":
      return <AuditEventView event={event} />;

    // EmailEvents have a type on the event:
    default:
      return <EmailEventView event={event} />;
  }
};

const AuditEventView = ({ event }: { event: AuditEvent }) => {
  const action = event.action;
  switch (action.type) {
    case "ApplicantCreated":
      return <ApplicantCreatedEventView event={event} action={action} />;
    case "UcasApplicantImported":
      return <UcasApplicantImportedEventView event={event} action={action} />;
    case "ApplicantDetailsUpdated":
      return <ApplicantDetailsUpdatedEventView event={event} action={action} />;
    case "ApplicationCreated":
      return <ApplicationCreatedEventView event={event} action={action} />;
    case "ApplicationHandover":
      return <ApplicationHandoverEventView event={event} action={action} />;
    case "ApplicationUpdated":
      return <ApplicationUpdatedEventView event={event} action={action} />;
    case "QualificationsUpdated":
      return <QualificationsUpdatedEventView event={event} action={action} />;
    case "NoteCreated":
      return <NoteCreatedEventView event={event} action={action} />;
    case "NoteUpdated":
      return <NoteUpdatedEventView event={event} action={action} />;
    case "NoteDeleted":
      return <NoteDeletedEventView event={event} action={action} />;
    case "AttachmentRequested":
      return <AttachmentRequestedEventView event={event} action={action} />;
    case "AttachmentApproved":
      return <AttachmentApprovedEventView event={event} action={action} />;
    case "AttachmentUnapproved":
      return <AttachmentUnapprovedEventView event={event} action={action} />;
    case "AttachmentDeleted":
      return <AttachmentDeletedEventView event={event} action={action} />;
    case "AttachmentUploadedToSITS":
      return <AttachmentUploadedToSITSEventView event={event} action={action} />;
    case "LabelSelected":
      return <LabelSelectedEventView event={event} action={action} />;
    case "LabelDeselected":
      return <LabelDeselectedEventView event={event} action={action} />;
    case "SelectorActionApplied":
      return <SelectorActionAppliedEventView event={event} action={action} />;
    case "ProgrammeClosed":
      return <ProgrammeClosedEventView event={event} action={action} />;
    case "PhoneCallAttempted":
      return <PhoneCallAttemptedView event={event} action={action} />;
    default:
      return <UnknownEventView event={event} />;
  }
};

const EmailEventView = ({ event }: { event: EmailEvent }) => {
  switch (event.type) {
    case "EmailSent":
      return <EmailSentEventView event={event} />;
    case "EmailPending":
      return <EmailPendingEventView event={event} />;
    case "EmailFailed":
      return <EmailFailedEventView event={event} />;
    default:
      return <UnknownEventView event={event} />;
  }
};

export const ApplicantCreatedEventView = ({ event, action }: { event: AuditEvent; action: ApplicantCreated }) => (
  <Segment>
    <AuditEventTitle title="Applicant Created" event={event} divider />
    <ApplicantDetailsView details={action.applicant.details} />
    <QualificationsDTOView qualifications={action.applicant.qualifications} />
  </Segment>
);

export const UcasApplicantImportedEventView = ({ event, action }: { event: AuditEvent; action: UcasApplicantImported }) => (
  <Segment>
    <AuditEventTitle title="UCAS Import" event={event} divider />
    <UcasImportView schoolCode={action.schoolCode} />
  </Segment>
);

export const ApplicantDetailsUpdatedEventView = ({ event, action }: { event: AuditEvent; action: ApplicantDetailsUpdated }) => (
  <Segment>
    <AuditEventTitle title="Applicant Details Updated" event={event} divider />
    <ApplicantDetailsView details={action.applicantDetails} />
  </Segment>
);
export const QualificationsUpdatedEventView = ({ event, action }: { event: AuditEvent; action: QualificationsUpdated }) => (
  <Segment>
    <AuditEventTitle title="Qualifications Updated" event={event} divider />
    <QualificationsDTOView qualifications={action.qualifications} />
  </Segment>
);

export const ApplicationCreatedEventView = ({ event, action }: { event: AuditEvent; action: ApplicationCreated }) => (
  <Segment>
    <AuditEventTitle title="Application Created" event={event} divider />
    <ApplicationView application={action.application} />
  </Segment>
);

export const ApplicationUpdatedEventView = ({ event, action }: { event: AuditEvent; action: ApplicationUpdated }) => (
  <Segment>
    <AuditEventTitle title="Application Updated" event={event} divider />
    <ApplicationView application={action.application} />
  </Segment>
);

export const ApplicationHandoverEventView = ({ event, action }: { event: AuditEvent; action: ApplicationHandover }) => (
  <Segment>
    <AuditEventTitle title={action.application.handover ? "Handed over to admissions" : "Retrieved from admissions"} event={event} divider />
    <ApplicationView application={action.application} />
  </Segment>
);

export const NoteCreatedEventView = ({ event, action }: { event: AuditEvent; action: NoteCreated }) => (
  <Segment>
    <AuditEventTitle title="Note created" event={event} divider />
    <NoteView note={action.note} />
  </Segment>
);

export const NoteUpdatedEventView = ({ event, action }: { event: AuditEvent; action: NoteUpdated }) => (
  <Segment>
    <AuditEventTitle title="Note updated" event={event} divider />
    <NoteView note={action.note} />
  </Segment>
);

export const NoteDeletedEventView = ({ event, action }: { event: AuditEvent; action: NoteDeleted }) => (
  <Segment>
    <AuditEventTitle title="Note deleted" event={event} divider />
    <NoteView note={action.note} />
  </Segment>
);

export const AttachmentRequestedEventView = ({ event, action }: { event: AuditEvent; action: AttachmentRequested }) => (
  <Segment>
    <AuditEventTitle title="Attachment Requested" event={event} divider />
    <AttachmentRequestedView message={action.message} fileType={action.attachmentType} />
  </Segment>
);

export const AttachmentApprovedEventView = ({ event, action }: { event: AuditEvent; action: AttachmentApproved }) => (
  <Segment>
    <AuditEventTitle title="Attachment Approved" event={event} divider />
    <AttachmentActionView fileId={action.attachmentId} fileName={action.fileName} fileType={action.attachmentType} />
  </Segment>
);

export const AttachmentUnapprovedEventView = ({ event, action }: { event: AuditEvent; action: AttachmentUnapproved }) => (
  <Segment>
    <AuditEventTitle title="Attachment Unapproved" event={event} divider />
    <AttachmentActionView fileId={action.attachmentId} fileName={action.fileName} fileType={action.attachmentType} />
  </Segment>
);

export const AttachmentDeletedEventView = ({ event, action }: { event: AuditEvent; action: AttachmentDeleted }) => (
  <Segment>
    <AuditEventTitle title="Attachment Deleted" event={event} divider />
    <AttachmentActionView fileId={action.attachmentId} fileName={action.fileName} fileType={action.attachmentType} />
  </Segment>
);

export const AttachmentUploadedToSITSEventView = ({ event, action }: { event: AuditEvent; action: AttachmentUploadedToSITS }) => (
  <Segment>
    <AuditEventTitle title="Attachment Uploaded to SITS" event={event} divider />
    <AttachmentUploadedToSITSActionView
      fileId={action.attachmentId}
      fileName={action.fileName}
      sitsFileName={action.sitsFileName}
      fileType={action.attachmentType}
    />
  </Segment>
);

export const LabelSelectedEventView = ({ event, action }: { event: AuditEvent; action: LabelSelected }) => (
  <Segment>
    <AuditEventTitle title="Label selected" event={event} />
    <br />
    <LabelView label={action.label} padded showSchool />
  </Segment>
);

export const LabelDeselectedEventView = ({ event, action }: { event: AuditEvent; action: LabelDeselected }) => (
  <Segment>
    <AuditEventTitle title="Label deselected" event={event} />
    <br />
    <LabelView label={action.label} padded showSchool />
  </Segment>
);

export const SelectorActionAppliedEventView = ({ event, action }: { event: AuditEvent; action: SelectorActionApplied }) => {
  let title: string | null;
  switch (action.sa.type) {
    case "ImportApplication":
      title = "New application imported from selector list";
      break;
    case "LinkApplication":
      title = "Application linked to selector list";
      break;
    case "ProgrammeChange":
      title = "Programme imported from selector list";
      break;
    case "NameChange":
      title = "Applicant name imported from selector list";
      break;
    case "TelephoneChange":
      title = "Contact telephone imported from selector list";
      break;
    case "NationalityChange":
      title = "Nationality imported from selector list";
      break;
    case "DateOfBirthChange":
      title = "Applicant date of birth imported from selector list";
      break;
    case "SitsHomeEmailChange":
      title = "Applicant SITS home email imported from selector list";
      break;
    case "StudentIdChange":
      title = "Student ID imported from selector list";
      break;
    case "InitialDecisionChange":
      title = "Initial decision imported from selector list";
      break;
    case "InitialResponseChange":
      title = "Initial response imported from selector list";
      break;
    case "ConfirmationDecisionChange":
      title = "Confirmation decision imported from selector list";
      break;
    case "ConfirmationResponseChange":
      title = "Confirmation response imported from selector list";
      break;
    case "ClearingDecisionChange":
      title = "Clearing decision imported from selector list";
      break;
    case "ClearingResponseChange":
      title = "Clearing response imported from selector list";
      break;
    case "ApplicationStatusChange":
      title = "Application status imported from selector list";
      break;
    case "FeeStatusChange":
      title = "Fee status imported from selector list";
      break;
    case "EntryPointChange":
      title = "Entry point imported from selector list";
      break;
    case "CocoChange":
      title = "COCO status imported from selector list";
      break;
    case "ErrorAction":
      title = "";
      break;
    default:
      checkExhausted(action.sa);
  }

  return title != null ? (
    <SelectorListActionView
      title={title}
      event={event}
      action={action.sa}
      row={action.selectorRow}
      applicant={action.applicant}
      application={action.application}
    />
  ) : (
    <UnknownEventView event={event} />
  );
};

export const ProgrammeClosedEventView = ({ event, action }: { event: AuditEvent; action: ProgrammeClosed }) => (
  <Segment>
    <AuditEventTitle title="Programme closed, application removed from waiting list" event={event} />
    <br />
    <ApplicationView application={action.application} />
  </Segment>
);

type SelectorListActionViewProps = {
  title: string;
  event: AuditEvent;
  action: SelectorAction;
  row: Opt<SelectorListDataRow>;
  applicant: Opt<ApplicantDTO>;
  application: Opt<Application>;
};

const SelectorListActionView = ({ title, event, action, row, applicant, application }: SelectorListActionViewProps) => (
  <Segment>
    <AuditEventTitle title={title} event={event} divider />
    {row && <SelectorListDataRowView row={row} applicant={applicant} application={application} showDiffs={false} highlightAction={action.type} />}
  </Segment>
);

export const PhoneCallAttemptedView = ({ event, action }: { event: AuditEvent; action: PhoneCallAttempted }) => (
  <Segment color={action.success ? "green" : "red"} background={action.success ? "#eeffee" : "#ffeeee"}>
    <AuditEventTitle title={action.success ? "Phone call placed" : "Phone call failed"} event={event} divider />
    <PhoneCallAttemptedDetailsView attempt={action} />
  </Segment>
);

export const EmailSentEventView = ({ event }: { event: EmailSent }) => (
  <Segment color="green" style={{ background: "#eeffee" }}>
    <EmailEventTitle title="Email sent" event={event} color="green" />
    <EmailView email={event.email} />
  </Segment>
);

export const EmailPendingEventView = ({ event }: { event: EmailPending }) => (
  <Segment color="green" style={{ background: "#eeffee" }}>
    <EmailEventTitle title="Email pending" event={event} color="green" />
    <EmailView email={event.email} />
  </Segment>
);

export const EmailFailedEventView = ({ event }: { event: EmailFailed }) => (
  <Segment color="red" style={{ background: "#ffeeee" }}>
    <EmailEventTitle title="Email failed" event={event} color="red" />
    <EmailView email={event.email} />
  </Segment>
);

export const UnknownEventView = ({ event }: { event: AuditEvent }) => (
  <Segment>
    <AuditEventTitle title="Unknown Event" event={event} divider />
    <pre>{JSON.stringify(event, null, 2)}</pre>
  </Segment>
);

type AuditEventTitleProps = {
  title: string;
  event: AuditEvent;
  divider?: boolean;
};

const AuditEventTitle = ({ title, event, divider }: AuditEventTitleProps) => (
  <div>
    <Label ribbon>{formatLondon(parseIso(event.timestamp), ddmmhhmm)}</Label>
    <strong>
      {title} by {event.userName || "Springboard"} ({event.userSchoolCode || "Central"})
    </strong>
    {divider && <Divider hidden />}
  </div>
);

type EmailEventTitleProps = {
  title: string;
  event: EmailSent | EmailFailed;
  color?: "green" | "red" | "orange" | "yellow" | "olive" | "teal" | "blue" | "violet" | "purple" | "pink" | "brown" | "grey" | "black";
};

const EmailEventTitle = ({ title, event, color }: EmailEventTitleProps) => (
  <div>
    <Label ribbon color={color}>
      {formatLondon(parseIso(event.timestamp), ddmmhhmm)}
    </Label>
    <strong>{title}</strong>
  </div>
);
