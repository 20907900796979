import { useWhoAmI } from "@qmspringboard/app/src/hooks/useWhoAmI";
import { createPermissions, noopPermissions, userPermissions } from "@qmspringboard/app/src/model/permission";

/**
 * Gather permissions from the WhoAmI data for the user. If the user is acting as another user then
 * that other user's permissions will be returned, otherwise the permissions for the logged-in user will
 * be returned.
 *
 * If the data is not yet loaded because the first call to the server hasn't completed yet then
 * this will return a set of noopPermissions. However, the hook should cause a component refresh
 * as soon as the load is complete.
 */
export const usePermissions = () => {
  const { data } = useWhoAmI();
  return data ? createPermissions(userPermissions(data.actingAs ? data.actingAs : data.user)) : noopPermissions;
};

export default usePermissions;
