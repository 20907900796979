import { fetchOfferScript } from "../api";
import { ApplicationChoiceEnum, OfferStatusEnum } from "../model/enums";
import { Application, TelephoneScript } from "../model/types";
import { Opt } from "../utils";
import { AppDispatch, AppThunkAction } from "./actions";
import { showModal } from "./modal";

export const willHaveScript = (appn: Application): boolean => {
  const { choice, offerStatus, id } = appn;
  if (id < 0) {
    return false;
  }
  if (choice === ApplicationChoiceEnum.Clearing) {
    switch (offerStatus) {
      case OfferStatusEnum.Made:
        return true;
      case OfferStatusEnum.MadePreRelease:
        return true;
      case OfferStatusEnum.WaitingList:
        return true;
      case OfferStatusEnum.Interview:
        return true;
      case OfferStatusEnum.Declined:
        return true;
      case OfferStatusEnum.Rejected:
        return true;
      default:
        return false;
    }
  }
  return false;
};

type Result = {
  ok: boolean;
  content?: Opt<TelephoneScript>;
  error?: unknown;
};

export function showApplicationScripts(applications: Application[]): AppThunkAction {
  return async (dispatch: AppDispatch) => {
    const scriptsToFetch = applications.filter(willHaveScript);

    const scripts: Result[] = await Promise.all(
      scriptsToFetch.map(async appn => {
        try {
          const resource = await fetchOfferScript(appn.id);
          return { ok: true, content: resource as TelephoneScript };
        } catch (error) {
          return { ok: false, error };
        }
      }),
    );

    const found = scripts.filter(s => s.ok);
    if (found.length) {
      dispatch(
        showModal("applicationScript", {
          scripts: found.map(f => f.content),
        }),
      );
    }
  };
}
