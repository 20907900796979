import update from "immutability-helper";
import React from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Dimmer, Icon, Label, Loader, Message, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import * as api from "../api";
import { AppError } from "../errors";
import { formatNameWithInitials } from "../model/name";
import { SearchResults, User } from "../model/types";
import { Opt, ReactNodeLike } from "../utils";
import { withModal, WithModalProps } from "../utils/modal";
import ClickableCell from "./ClickableCell";

type Props = {
  results: SearchResults<User>;
  sortComponent: (by: string, label: string, initialDir?: "asc" | "desc") => ReactNodeLike;
  fetching: boolean;
  fetchError: Opt<AppError>;
  bulkLockoutUser: Function;
  refetch: Function;
};

const ResponsiveTable = styled.div`
  overflow-x: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;
const NoItems = ({ fetchError }: { fetchError: AppError | null }) => (
  <Table.Row key="noitems">
    <Table.Cell colSpan={5} textAlign="center">
      <Segment basic>{fetchError ? `Could not load results. ${fetchError.allMessages()}` : "Nothing to show"}</Segment>
    </Table.Cell>
  </Table.Row>
);

const bulkLockoutUser = (users: User[]) => {
  return async (dispatch: Function, getState: Function) => {
    return await api.bulkLockoutUser(users);
  };
};

const UserItem = ({ user, selected, handleSelectionChange }: { user: User; selected: boolean; handleSelectionChange: (value: boolean) => void }) => {
  const url = `/admin/user/${user.id}`;
  const canLogIn: boolean = user.canLogin;

  return (
    <Table.Row key={user.username}>
      <Table.Cell>
        <Checkbox checked={selected} onChange={(_, data) => handleSelectionChange(data?.checked ?? false)} />
      </Table.Cell>
      <ClickableCell to={url}>{formatNameWithInitials(user.surname, user.forenames)}</ClickableCell>
      <ClickableCell to={url}>{user.username}</ClickableCell>
      <ClickableCell to={url}>{user.email}</ClickableCell>
      <ClickableCell to={url}>
        {user.roles.map((ur, index) => (
          <Label color="green" key={index}>
            {ur.role.name}
          </Label>
        ))}
      </ClickableCell>
      <ClickableCell to={url}>{user.defaultSchoolCode}</ClickableCell>
      <ClickableCell to={url}>
        <Icon name={canLogIn ? "checkmark" : "close"} color={canLogIn ? "green" : "red"} />
      </ClickableCell>
    </Table.Row>
  );
};

interface SelectionSet {
  [name: string]: boolean;
}

const UserList = ({ results: { items }, fetching, fetchError, sortComponent, bulkLockoutUser, showModal, refetch }: Props & WithModalProps) => {
  const [selection, setSelection] = React.useState<SelectionSet>({});
  const [busy, setBusy] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const selectAll = (state: boolean) => {
    if (!state) {
      setSelection({});
    } else {
      const set = items.reduce((a, c) => ({ ...a, [c.id]: true }), {});
      setSelection({
        ...selection,
        ...set,
      });
    }
  };

  const anySelected = Object.keys(selection).length > 0;
  const allSelected = items.length === Object.keys(selection).length;

  const handleBulkSet = async () => {
    setBusy(true);
    setError(null);
    const usersToUpdate = items.filter(item => selection[item.id]);
    try {
      await bulkLockoutUser(usersToUpdate);
      setBusy(false);
      setSelection({});
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        throw e;
      }
      setBusy(false);
    }
  };

  const handleDisable = () => {
    showModal("confirm", {
      onPrimaryClick: () => {
        handleBulkSet();
      },
      content: "Are you sure you want to disable selected users?",
    });
  };

  const onChange = (id: number) => (state: boolean) => {
    if (state) {
      setSelection({ ...selection, [id]: true });
    } else {
      setSelection(update(selection, { $unset: [id] }));
    }
  };

  return (
    <Dimmer.Dimmable dimmed={busy || fetching}>
      <Dimmer active={busy || fetching} inverted>
        <Loader>Loading</Loader>
      </Dimmer>

      {error && <Message header="Couldn't bulk update users" content="There was a problem updating users" negative />}

      <ResponsiveTable>
        <Table selectable unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  indeterminate={!allSelected && anySelected}
                  checked={allSelected}
                  onChange={(_, data) => selectAll(data?.checked ?? false)}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>{sortComponent("name", "Name")}</Table.HeaderCell>
              <Table.HeaderCell>{sortComponent("username", "Username")}</Table.HeaderCell>
              <Table.HeaderCell>{sortComponent("email", "Email")}</Table.HeaderCell>
              <Table.HeaderCell>Roles</Table.HeaderCell>
              <Table.HeaderCell>{sortComponent("school", "School")}</Table.HeaderCell>
              <Table.HeaderCell>Can Log In</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {anySelected && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={11} selectable={false}>
                  <Button onClick={handleDisable}>Disable User</Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {items.length === 0 ? (
              <NoItems fetchError={fetchError || null} />
            ) : (
              items.map(user => (
                <UserItem key={user.id} user={user} handleSelectionChange={onChange(user.id)} selected={selection[user.id] || false} />
              ))
            )}
          </Table.Body>
        </Table>
      </ResponsiveTable>
    </Dimmer.Dimmable>
  );
};

export const withBulkUpdate = connect(null, (dispatch: Function) => ({
  bulkLockoutUser: (users: User[]) => dispatch(bulkLockoutUser(users)),
}));

export default withModal(withBulkUpdate(UserList));
