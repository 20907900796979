import { blankApplicant } from "@qmspringboard/app/src/api";
import { useQuery } from "react-query";
import { ApplicantEditorDTO } from "@qmspringboard/shared/src/model/applications.generated";

export const defaultApplicant: ApplicantEditorDTO = {
  applicant: {
    id: -1,
    createdAt: "1970-01-01T00:00:00Z",
    details: {
      ucasPersonalId: null,
      studentId: null,
      surname: null,
      forenames: null,
      telephone1: null,
      telephone2: null,
      feeStatus: null,
      countryOfDomicile: null,
      ukResidency: null,
      ukImmigrationStatus: null,
      email: null,
      sitsHomeEmail: null,
      dateOfBirth: null,
      version: 0,
    },
    qualifications: {
      list: [],
      english: null,
      secondaryEducation: null,
      version: 0,
    },
  },
  applications: [],
  labels: [],
  notes: [],
};

export function useBlankApplicant(): ApplicantEditorDTO | null {
  const queryParams = {
    queryKey: "blankApplicant",
    queryFn: () => blankApplicant(),
    // The only reason we'd need it to refresh is if the labels change. But the call
    // is only made once when the applicant/new page is rendered so no real benefit to
    // caching for a long time.
    staleTime: 0,
  };

  const { data } = useQuery<ApplicantEditorDTO>("blank", queryParams);

  return data || null;
}
