import * as api from "../../api";
import * as pot from "../../api/pot";
import { SearchParams } from "../../model/search";
import { ApplicationSummary, SchoolCode, SearchResults } from "../../model/types";
import { Opt } from "../../utils";
import { AppReducer } from "../actions";
import { fetchWithMutex, validationErrorHandler } from "../fetch";
import { rootSelector as schoolSelector } from "../school";
import { AppState } from "../state";
import * as teams from "../teams";
import { MyAction } from "./actions";
import { initialState, MyState } from "./state";

///////////////
// SELECTORS //
///////////////

export function myState(state: AppState): MyState {
  return schoolSelector(state).applicationSearch;
}

export const results = myState;

export function fetching(state: AppState): boolean {
  return pot.fetching(myState(state));
}

export interface DownloadCsvUrlOptions {
  school?: Opt<SchoolCode>;
  q?: Opt<string>;
  sortby?: Opt<string>;
  sortdir?: Opt<string>;
  personal?: boolean;
  notes?: boolean;
}

export const downloadCsvUrl = (state: AppState) => (query: DownloadCsvUrlOptions) => {
  const school = teams.currentSchoolCode(state);
  return api.applicationsCsvUrl({ ...query, school });
};

/////////////
// ACTIONS //
/////////////

const FETCHING = "applicationSearch/FETCHING";
const RESET = "applicationSearch/RESET";
const FETCHED = "applicationSearch/FETCHED";
const FAILED = "applicationSearch/FAILED";

/////////////////////
// ACTION CREATORS //
/////////////////////

export function reset(): MyAction {
  return { type: RESET };
}

export function search(school: Opt<string>, params: SearchParams) {
  return fetchWithMutex<SearchResults<ApplicationSummary>>({
    mutex: "applicationSearch",

    request(dispatch, getState) {
      return api.searchApplications(school, params);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: FETCHING, requestId });
    },

    success(dispatch, getState, results) {
      dispatch({ type: FETCHED, results });
    },

    error: validationErrorHandler((error, messages) => ({
      type: FAILED,
      error,
      messages,
    })),
  });
}

/////////////
// REDUCER //
/////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    case FETCHING:
      return pot.pending(action.requestId);
    case FETCHED:
      return pot.ready(action.results);
    case FAILED:
      return pot.failed(action.error);
    default:
      return state;
  }
};

export default reducer;
