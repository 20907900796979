import { push } from "connected-react-router";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { UnknownClientError } from "../../errors";
import { roleRule } from "../../model/role";
import { Checked, Message, Role } from "../../model/types";
import { AppReducer } from "../actions";
import { fetchWithMutex, validationErrorHandler } from "../fetch";
import { AppState } from "../state";
import { emptyRole, initialState, MyState } from "./state";

///////////////
// Selectors //
///////////////

export const myState = (state: AppState): MyState => state.route.roleUpdate;

export const role = (state: AppState): Role => myState(state).role;

export const messages = (state: AppState): Message[] => myState(state).clientMessages.concat(myState(state).serverMessages);

export const fetching = (state: AppState): boolean => myState(state).fetching;

export const fetched = (state: AppState): boolean => myState(state).fetched;

/////////////
// Actions //
/////////////

const INITIALISE = "roleUpdate/INITIALISE";
const LOAD_START = "roleUpdate/LOAD_START";
const LOAD_SUCCESS = "roleUpdate/LOAD_SUCCESS";
const UPDATE = "roleUpdate/UPDATE";
const SAVE_START = "roleUpdate/SAVE_START";
const SAVE_SUCCESS = "roleUpdate/SAVE_SUCCESS";
const DELETE_START = "roleUpdate/DELETE_START";
const DELETE_SUCCESS = "roleUpdate/DELETE_SUCCESS";
const SET_SERVER_MESSAGES = "roleUpdate/SET_SERVER_MESSAGES";

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState(), action) => {
  function clearServerMessages(state: MyState): MyState {
    return { ...state, serverMessages: [] };
  }

  function commitChanges(state: MyState) {
    return { ...state, originalGroup: state.role };
  }

  function updateAndValidate(state: MyState, role: Role) {
    return { ...state, role, clientMessages: roleRule(role) };
  }

  switch (action.type) {
    case INITIALISE:
      return commitChanges(
        updateAndValidate(
          {
            ...state,
            fetching: false,
            serverMessages: [],
          },
          emptyRole(),
        ),
      );
    case LOAD_START:
      return { ...state, fetching: true };
    case LOAD_SUCCESS:
      return commitChanges(
        updateAndValidate(
          {
            ...state,
            fetching: false,
          },
          action.role,
        ),
      );
    case UPDATE:
      return clearServerMessages(updateAndValidate(state, action.role));
    case SAVE_START:
      return { ...state, fetching: true };
    case SAVE_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.role));
    case SET_SERVER_MESSAGES:
      return { ...state, fetching: false, serverMessages: action.messages };
    default:
      return state;
  }
};

export default reducer;

/////////////////////
// Action Creators //
/////////////////////

export const initialise = () => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: INITIALISE,
    });
  };
};

export function load(id: number) {
  return fetchWithMutex<Role>({
    mutex: "roleLoad",

    request(_dispatch, getState) {
      return api.fetchRole(id);
    },

    pending(dispatch, _getState) {
      dispatch({ type: LOAD_START });
    },

    success(dispatch, getState, role) {
      dispatch({
        type: LOAD_SUCCESS,
        role,
      });
    },
  });
}

export const update = (role: Role) => ({ type: UPDATE, role });

export function cancel() {
  return (dispatch: Function) => {
    dispatch(push("/admin/role"));
  };
}

export function save(role: Role) {
  return fetchWithMutex<Checked<Role>>({
    mutex: "roleSave",

    request(_dispatch, getState) {
      return role.id >= 0 ? api.updateRole(role) : api.insertRole(role);
    },

    pending(dispatch, _getState) {
      dispatch({ type: SAVE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, _getState, checked) {
      checked.data && toast.success("Role saved");
      checked.data && dispatch({ type: SAVE_SUCCESS, role: checked.data });
      checked.messages && dispatch({ type: SET_SERVER_MESSAGES, messages: checked.messages });
      checked.data && dispatch(push("/admin/role"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}

export function del(role: Role) {
  return fetchWithMutex({
    mutex: "roleDelete",

    request(_dispatch, getState) {
      return role.id >= 0 ? api.deleteRole(role.id) : Promise.reject(new UnknownClientError("Cannot delete an unsaved role"));
    },

    pending(dispatch, _getState) {
      dispatch({ type: DELETE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, _getState, _result) {
      toast.success("Role deleted");
      dispatch({ type: DELETE_SUCCESS });
      dispatch(push("/admin/role"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}
