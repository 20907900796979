import React, { ComponentType, ReactElement } from "react";
import { Permissions } from "../model/permission";
import { Team, TeamCode } from "../model/team";
import * as teams from "../reducers/teams";
import usePermissions from "@qmspringboard/app/src/hooks/usePermissions";

interface Props {
  permissionCheck: (permissions: Permissions, teamCode: TeamCode) => boolean;
  currentTeam: Team;
  children: ReactElement;
}

const RequirePermission = (props: Props) => {
  const permissions: Permissions = usePermissions();
  const { permissionCheck, currentTeam, children } = props;
  if (permissionCheck(permissions, currentTeam.code)) {
    return <div>{children}</div>;
  } else {
    window.location.assign("/");
    return <span />;
  }
};

const ConnectedRequirePermission = teams.withCurrentTeam(RequirePermission);

export default function requirePermission<A>(
  ComposedComponent: ComponentType<A>,
  permissionCheck: (permissions: Permissions, teamCode: TeamCode) => boolean,
) {
  return function RequirePermission(props: A) {
    return (
      <ConnectedRequirePermission permissionCheck={permissionCheck}>
        <ComposedComponent {...props} />
      </ConnectedRequirePermission>
    );
  };
}
