import { applicationChoiceToMethod, applicationRequiresConfirmationEmail } from "@qmspringboard/shared/dist/model/application";
import { isEqual, sortBy, without } from "lodash";
import { combineReducers, Reducer } from "redux";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { ValidationError } from "../../errors";
import { Applicant, ApplicantEditorState, emptyDuplicateApplicants, fromApplicantEditorDTO, Qualifications } from "../../model/applicant";
import { applicantEditorStateRule } from "../../model/applicantRules";
import { ApplicationChoiceEnum, OfferStatusEnum } from "../../model/enums";
import type { Messages } from "../../model/errors";
import type {
  ApplicantDetails,
  ApplicantEditorDTO,
  Application,
  ApplicationChoice,
  Checked,
  Label,
  Note,
  SearchResults,
  User,
} from "../../model/types";
import { ProgrammeCode, SchoolCode } from "../../model/types";
import { Opt, prefixMessages, replace } from "../../utils";
import { AppAction, AppDispatch, AppThunkAction } from "../actions";
import { showApplicationScripts, willHaveScript } from "../applicantActions";
import attachmentReducer from "../applicantAttachments";
import { MyAction as AttachmentsAction } from "../applicantAttachments/actions";
import { MyState as AttachmentsState } from "../applicantAttachments/state";
import {
  applicationEmailConfirmationModalCpsStep,
  duplicateApplicationConfirmationModalCpsStep,
  handoverConfirmEmailModalCpsStep,
  handoverConfirmModalCpsStep,
} from "../applicantCreateOrUpdate";
import { defaultErrorHandler, fetchWithMutex, validationErrorHandler } from "../fetch";
import { MyAction as FetchAction } from "../fetch/actions";
import { showModal } from "../modal";
import * as programmeList from "../programmeList";
import { rootSelector as routeSelector } from "../route";
import { AppState, GetState } from "../state";
import * as teams from "../teams";
import { MyAction } from "./actions";
import { CombinedState, emptySections, initialState, MyState, Sections } from "./state";

/////////////
// Actions //
/////////////

const LOADING = "applicantUpdate/LOADING";
const LOADED = "applicantUpdate/LOADED";
const APPLICANT_DETAILS_UPDATE = "applicantUpdate/APPLICANT_DETAILS_UPDATE";
const APPLICANT_DETAILS_EDIT_START = "applicantUpdate/APPLICANT_DETAILS_EDIT_START";
const APPLICANT_DETAILS_EDIT_CANCEL = "applicantUpdate/APPLICANT_DETAILS_EDIT_CANCEL";
const APPLICANT_DETAILS_SAVE_START = "applicantUpdate/APPLICANT_DETAILS_SAVE_START";
const APPLICANT_DETAILS_SAVE_SUCCESS = "applicantUpdate/APPLICANT_DETAILS_SAVE_SUCCESS";
const DUPLICATE_UCAS_START = "applicantUpdate/DUPLICATE_UCAS_START";
const DUPLICATE_UCAS_SUCCESS = "applicantUpdate/DUPLICATE_UCAS_SUCCESS";
const DUPLICATE_STUDENTID_START = "applicantUpdate/DUPLICATE_STUDENTID_START";
const DUPLICATE_STUDENTID_SUCCESS = "applicantUpdate/DUPLICATE_STUDENTID_SUCCESS";
const DUPLICATE_EMAIL_START = "applicantUpdate/DUPLICATE_EMAIL_START";
const DUPLICATE_EMAIL_SUCCESS = "applicantUpdate/DUPLICATE_EMAIL_SUCCESS";
const QUALIFICATIONS_UPDATE = "applicantUpdate/QUALIFICATIONS_UPDATE";
const QUALIFICATIONS_EDIT_START = "applicantUpdate/QUALIFICATIONS_EDIT_START";
const QUALIFICATIONS_EDIT_CANCEL = "applicantUpdate/QUALIFICATIONS_EDIT_CANCEL";
const QUALIFICATIONS_SAVE_START = "applicantUpdate/QUALIFICATIONS_SAVE_START";
const QUALIFICATIONS_SAVE_SUCCESS = "applicantUpdate/QUALIFICATIONS_SAVE_SUCCESS";
const APPLICATION_SELECT = "applicantUpdate/APPLICATION_SELECT";
const APPLICATION_ADD_START = "applicantUpdate/APPLICATION_ADD_START";
const APPLICATION_ADD_SUCCESS = "applicantUpdate/APPLICATION_ADD_SUCCESS";
const APPLICATION_UPDATE = "applicantUpdate/APPLICATION_UPDATE";
const APPLICATION_EDIT_START = "applicantUpdate/APPLICATION_EDIT_START";
const APPLICATION_EDIT_CANCEL = "applicantUpdate/APPLICATION_EDIT_CANCEL";
const APPLICATION_SAVE_START = "applicantUpdate/APPLICATION_SAVE_START";
const APPLICATION_SAVE_SUCCESS = "applicantUpdate/APPLICATION_SAVE_SUCCESS";
const APPLICATION_REMOVE = "applicantUpdate/APPLICATION_REMOVE";
const APPLICATION_CANCEL_PROGRAMME_CHANGE = "applicantUpdate/APPLICATION_CANCEL_PROGRAMME_CHANGE";
const APPLICATION_COMPLETE_PROGRAMME_CHANGE = "applicantUpdate/APPLICATION_COMPLETE_PROGRAMME_CHANGE";
const APPLICATION_SUGGEST_EXPIRY_SUCCESS = "applicantUpdate/APPLICATION_SUGGEST_EXPIRY_SUCCESS";
const APPLICATION_SUGGEST_EXPIRY_START = "applicantUpdate/APPLICATION_SUGGEST_EXPIRY_START";
const APPLICATION_EMAIL_RESEND_START = "applicantUpdate/APPLICATION_EMAIL_RESEND_START";
const APPLICATION_EMAIL_RESEND_SUCCESS = "applicantUpdate/APPLICATION_EMAIL_RESEND_SUCCESS";
const LABEL_FETCH_START = "applicantUpdate/LABEL_FETCH_START";
const LABEL_FETCH_SUCCESS = "applicantUpdate/LABEL_FETCH_SUCCESS";
const LABEL_UPDATE = "applicantUpdate/LABEL_UPDATE";
const LABEL_SAVE_START = "applicantUpdate/LABEL_SAVE_START";
const LABEL_SAVE_SUCCESS = "applicantUpdate/LABEL_SAVE_SUCCESS";
const SET_HANDOVER_START = "applicantUpdate/SET_HANDOVER_START";
const SET_HANDOVER_SUCCESS = "applicantUpdate/SET_HANDOVER_SUCCESS";
const NOTE_ADD_START = "applicantUpdate/NOTE_ADD_START";
const NOTE_ADD_SUCCESS = "applicantUpdate/NOTE_ADD_SUCCESS";
const NOTE_UPDATE = "applicantUpdate/NOTE_UPDATE";

const NOTE_EDIT_START = "applicantUpdate/NOTE_EDIT_START";
const NOTE_EDIT_CANCEL = "applicantUpdate/NOTE_EDIT_CANCEL";
const NOTE_SAVE_START = "applicantUpdate/NOTE_SAVE_START";
const NOTE_SAVE_SUCCESS = "applicantUpdate/NOTE_SAVE_SUCCESS";
const NOTE_DELETE_START = "applicantUpdate/NOTE_DELETE_START";
const NOTE_DELETE_SUCCESS = "applicantUpdate/NOTE_DELETE_SUCCESS";
const SERVER_VALIDATION_FAILURE = "applicantUpdate/SERVER_VALIDATION_FAILURE";

//////////////
/// REDUCER //
//////////////

function reducer(state: MyState = initialState, action: MyAction | FetchAction): MyState {
  function commitChanges(state: MyState): MyState {
    return { ...state, dtoInitial: state.dto };
  }

  function startSaving(state: MyState, sections: Sections): MyState {
    return { ...state, saving: sections };
  }

  function finishSaving(state: MyState): MyState {
    return { ...state, saving: emptySections };
  }

  function startEditing(state: MyState, sections: Sections): MyState {
    return { ...state, editing: sections };
  }

  function finishEditing(state: MyState): MyState {
    return { ...state, editing: emptySections };
  }

  function clearDuplicateApplicants(state: MyState): MyState {
    return {
      ...state,
      dto: { ...state.dto, duplicates: emptyDuplicateApplicants },
    };
  }

  function boundSelectedApplicationIndex(state: MyState): MyState {
    const index = state.selectedApplicationIndex;
    const appns = state.dto.applications;
    if (appns.length === 0) {
      return { ...state, selectedApplicationIndex: null };
    } else if (index == null || index < 0) {
      return { ...state, selectedApplicationIndex: 0 };
    } else if (index >= appns.length) {
      return { ...state, selectedApplicationIndex: appns.length - 1 };
    } else {
      return state;
    }
  }

  function updateAndValidate(state: MyState, dto: ApplicantEditorState): MyState {
    const clientMessages = applicantEditorStateRule(dto);
    return boundSelectedApplicationIndex({ ...state, clientMessages, dto });
  }

  function updateAndValidateApplicantDetails(state: MyState, details: ApplicantDetails): MyState {
    return updateAndValidate(state, {
      ...state.dto,
      applicant: {
        ...state.dto.applicant,
        details,
      },
    });
  }

  function updateAndValidateQualifications(state: MyState, qualifications: Qualifications): MyState {
    return updateAndValidate(state, {
      ...state.dto,
      applicant: {
        ...state.dto.applicant,
        qualifications,
      },
    });
  }

  function updateAndValidateApplications(state: MyState, applications: Application[]): MyState {
    return updateAndValidate(state, {
      ...state.dto,
      applications,
    });
  }

  function updateAndValidateLabels(state: MyState, labels: Label[]): MyState {
    const sorted = sortBy(labels, label => `${label.schoolCode}-${label.labelType}-${label.text}`.toLowerCase());
    return {
      ...updateAndValidate(state, { ...state.dto, labels: sorted }),
      // Commit changes to notes to dtoInitial immediately,
      // in case someone clicks "Cancel" on one of the main editable components:
      dtoInitial: { ...state.dtoInitial, labels: sorted },
    };
  }

  function updateAndValidateNotes(state: MyState, notes: Note[]): MyState {
    return {
      ...updateAndValidate(state, { ...state.dto, notes }),
      // Commit changes to notes to dtoInitial immediately,
      // in case someone clicks "Cancel" on one of the main editable components:
      dtoInitial: { ...state.dtoInitial, notes },
    };
  }

  function selectApplication(state: MyState, application: Application): MyState {
    const index = state.dto.applications.indexOf(application);
    return {
      ...state,
      selectedApplicationIndex: index >= 0 ? index : null,
    };
  }

  function selectNote(state: MyState, note: Note): MyState {
    const index = state.dto.notes.indexOf(note);
    return {
      ...state,
      selectedNoteIndex: index >= 0 ? index : null,
    };
  }

  switch (action.type) {
    case LOADING:
      return {
        ...state,
        fetching: true,
      };

    case LOADED:
      return commitChanges(
        updateAndValidate(
          {
            ...state,
            fetching: false,
            fetched: true,
          },
          action.dto,
        ),
      );

    case APPLICANT_DETAILS_UPDATE:
      return state.editing.details ? updateAndValidateApplicantDetails(state, action.details) : state;

    case APPLICANT_DETAILS_EDIT_START:
      return startEditing(state, { ...state.editing, details: true });

    case APPLICANT_DETAILS_EDIT_CANCEL:
      return finishEditing(clearDuplicateApplicants(updateAndValidateApplicantDetails(state, state.dtoInitial.applicant.details)));

    case APPLICANT_DETAILS_SAVE_START:
      return startSaving(state, { ...state.saving, details: true });

    case APPLICANT_DETAILS_SAVE_SUCCESS:
      return state.editing.details
        ? commitChanges(finishEditing(finishSaving(clearDuplicateApplicants(updateAndValidateApplicantDetails(state, action.details)))))
        : state;

    case DUPLICATE_UCAS_START:
      return state;

    case DUPLICATE_UCAS_SUCCESS:
      return updateAndValidate(state, {
        ...state.dto,
        duplicates: {
          ...state.dto.duplicates,
          ucasPersonalId: action.applicants.items,
        },
      });

    case DUPLICATE_STUDENTID_START:
      return state;

    case DUPLICATE_STUDENTID_SUCCESS:
      return updateAndValidate(state, {
        ...state.dto,
        duplicates: {
          ...state.dto.duplicates,
          studentId: action.applicants.items,
        },
      });

    case DUPLICATE_EMAIL_START:
      return state;

    case DUPLICATE_EMAIL_SUCCESS:
      return updateAndValidate(state, {
        ...state.dto,
        duplicates: {
          ...state.dto.duplicates,
          email: action.applicants.items,
        },
      });

    case QUALIFICATIONS_UPDATE:
      return state.editing.qualifications ? updateAndValidateQualifications(state, action.qualifications) : state;

    case QUALIFICATIONS_EDIT_START:
      return startEditing(state, { ...state.editing, qualifications: true });

    case QUALIFICATIONS_EDIT_CANCEL:
      return finishEditing(updateAndValidateQualifications(state, state.dtoInitial.applicant.qualifications));

    case QUALIFICATIONS_SAVE_START:
      return startSaving(state, { ...state.saving, qualifications: true });

    case QUALIFICATIONS_SAVE_SUCCESS:
      return state.editing.qualifications
        ? commitChanges(finishEditing(finishSaving(updateAndValidateQualifications(state, action.qualifications))))
        : state;

    case APPLICATION_SELECT: //todo validate?
      return {
        ...state,
        selectedApplicationIndex: state.dto.applications.indexOf(action.application),
      };

    case APPLICATION_EDIT_START:
      return startEditing(state, { ...state.editing, application: true });

    case APPLICATION_EDIT_CANCEL:
      return finishEditing(updateAndValidateApplications(state, state.dtoInitial.applications));

    case APPLICATION_UPDATE:
      return updateAndValidateApplications(state, replace(state.dto.applications, action.oldApplication, action.newApplication));

    case APPLICATION_ADD_START:
      return state;

    case APPLICATION_ADD_SUCCESS:
      return startEditing(
        selectApplication(updateAndValidateApplications(state, state.dto.applications.concat([action.application])), action.application),
        { ...state.editing, application: true },
      );

    case APPLICATION_SAVE_START:
      return startSaving(state, { ...state.saving, application: true });

    case APPLICATION_SAVE_SUCCESS:
      return commitChanges(
        finishEditing(
          finishSaving(updateAndValidateApplications(state, replace(state.dto.applications, action.oldApplication, action.newApplication))),
        ),
      );

    case APPLICATION_CANCEL_PROGRAMME_CHANGE: {
      const applicationId = action.application.id;

      const oldApplication = applicationId && state.dtoInitial.applications.find(appn => appn.id && applicationId);

      if (oldApplication) {
        return updateAndValidateApplications(
          state,
          replace(state.dto.applications, action.application, {
            ...action.application,
            programmeCode: oldApplication.oldProgrammeCode || oldApplication.programmeCode,
            oldProgrammeCode: null,
          }),
        );
      } else {
        return state;
      }
    }

    case APPLICATION_COMPLETE_PROGRAMME_CHANGE:
      return updateAndValidateApplications(
        state,
        replace(state.dto.applications, action.application, {
          ...action.application,
          oldProgrammeCode: null,
        }),
      );

    case APPLICATION_REMOVE:
      return finishEditing(updateAndValidateApplications(state, without(state.dto.applications, action.application)));

    case APPLICATION_SUGGEST_EXPIRY_START:
      return state;

    case APPLICATION_SUGGEST_EXPIRY_SUCCESS:
      return updateAndValidateApplications(
        state,
        replace(state.dto.applications, action.application, {
          ...action.application,
          offerExpiry: action.offerExpiry,
        }),
      );

    case APPLICATION_EMAIL_RESEND_START:
      return startSaving(state, { ...state.saving, application: true });

    case APPLICATION_EMAIL_RESEND_SUCCESS:
      return finishSaving(state);

    case LABEL_FETCH_START:
      return state;

    case LABEL_FETCH_SUCCESS:
      return updateAndValidateLabels(state, action.labels);

    case LABEL_UPDATE:
      return updateAndValidateLabels(state, replace(state.dto.labels, action.oldLabel, action.newLabel));

    case LABEL_SAVE_START:
      return state;

    case LABEL_SAVE_SUCCESS:
      return updateAndValidateLabels(state, replace(state.dto.labels, action.oldLabel, action.newLabel));

    case SET_HANDOVER_START:
      return state;

    case SET_HANDOVER_SUCCESS: {
      const appn0 = action.oldApplication;
      const appn1 = action.newApplication;

      // Update the relevant application:
      const state1 = updateAndValidateApplications(state, replace(state.dto.applications, appn0, appn1));

      // Commit changes to the relevant application only:
      return {
        ...state1,
        dtoInitial: {
          ...state1.dtoInitial,
          applications: state1.dtoInitial.applications.map(appn => (appn.id === appn1.id ? appn1 : appn)),
        },
      };
    }

    case NOTE_EDIT_START:
      return startEditing({ ...state, selectedNoteIndex: state.dto.notes.indexOf(action.note) }, { ...state.editing, note: true });

    case NOTE_EDIT_CANCEL:
      return updateAndValidateNotes(state, state.dtoInitial.notes);

    case NOTE_UPDATE:
      return updateAndValidateNotes(state, replace(state.dto.notes, action.oldNote, action.newNote));

    case NOTE_ADD_START:
      return state;

    case NOTE_ADD_SUCCESS:
      return startEditing(selectNote(updateAndValidateNotes(state, [action.note].concat(state.dto.notes)), action.note), {
        ...state.editing,
        note: true,
      });

    case NOTE_SAVE_START:
      return startSaving(state, { ...state.saving, note: true });

    case NOTE_SAVE_SUCCESS:
      return updateAndValidateNotes(state, replace(state.dto.notes, action.oldNote, action.newNote));

    case NOTE_DELETE_START:
      return state;

    case NOTE_DELETE_SUCCESS:
      return finishEditing(updateAndValidateNotes(state, without(state.dto.notes, action.note)));

    case SERVER_VALIDATION_FAILURE:
      return { ...state, serverMessages: action.messages };

    case "fetch/ERROR":
      return finishSaving(state);

    default:
      return state;
  }
}

// Tweak the type on attachmentRedicer to help type inference further up the chain:
const widenedAttachmentReducer: Reducer<AttachmentsState, AttachmentsAction> = attachmentReducer;

const combinedReducer: Reducer<CombinedState, MyAction> = combineReducers({
  applicant: reducer,
  applicantAttachments: widenedAttachmentReducer,
});

export default combinedReducer;

////////////////
/// Selectors //
////////////////

export function rootSelector(state: AppState): CombinedState {
  return routeSelector(state).applicantUpdate;
}

function myState(state: AppState): MyState {
  return rootSelector(state).applicant;
}

export function dto(state: AppState): ApplicantEditorState {
  return myState(state).dto;
}

export function dtoInitial(state: AppState): ApplicantEditorState {
  return myState(state).dtoInitial;
}

export function applicationInitial(state: AppState, id: number): Application | undefined {
  return dtoInitial(state).applications.find(appn => appn.id === id);
}

export function applications(state: AppState): Application[] {
  return dto(state).applications;
}

export function labels(state: AppState): Label[] {
  return dto(state).labels;
}

export function notes(state: AppState): Note[] {
  return dto(state).notes;
}

export function messages(state: AppState): Messages {
  const client = myState(state).clientMessages;
  const server = myState(state).serverMessages;
  return client.concat(server);
}

export function saving(state: AppState): Sections {
  return myState(state).saving;
}

export function editing(state: AppState): Sections {
  return myState(state).editing;
}

export function fetching(state: AppState): boolean {
  return myState(state).fetching;
}

export function fetched(state: AppState): boolean {
  return myState(state).fetched;
}

// this is the raw state used by RequirementsCheck
export function qualifications(state: AppState): Qualifications {
  return dto(state).applicant.qualifications;
}

export const selectedApplicationIndex = (state: AppState): number | null => {
  return myState(state).selectedApplicationIndex;
};

export const selectedApplication = (state: AppState): Application | null => {
  const index = selectedApplicationIndex(state);
  return index == null ? null : dto(state).applications[index];
};

export const selectedApplicationInitial = (state: AppState): Application | null => {
  const index = selectedApplicationIndex(state);
  return index == null ? null : dtoInitial(state).applications[index];
};

export const applicationUnsavedChanges = (state: AppState): boolean => {
  return myState(state).editing.application || myState(state).saving.application;
};

export const editingApplication = (state: AppState): Application | null => {
  return myState(state).editing.application ? selectedApplication(state) : null;
};

export const hasApplicationEmail = (state: AppState): boolean => {
  const details = dto(state).applicant.details;
  const appn = selectedApplication(state);
  if (appn == null) {
    return false;
  } else {
    switch (appn.offerStatus) {
      case OfferStatusEnum.Made:
      case OfferStatusEnum.MadePreRelease:
      case OfferStatusEnum.WaitingList:
      case OfferStatusEnum.Interview:
      case OfferStatusEnum.Rejected:
        return details.email != null;
      default:
        return false;
    }
  }
};

export const anythingEditing = (state: AppState): boolean => {
  return myState(state).editing.details || myState(state).editing.qualifications || myState(state).editing.application;
};

export const anythingDirty = (state: AppState): (() => boolean) => {
  return () => !isEqual(myState(state).dto, myState(state).dtoInitial);
};

export const savingApplication = (state: AppState): boolean => {
  return saving(state).application;
};

export const hasApplicationScript = (state: AppState): boolean => {
  const selected = selectedApplication(state);
  return selected ? willHaveScript(selected) : false;
};

/////////////////////
// Action Creators //
/////////////////////

function filterOutApplicant(id: number) {
  return (results: SearchResults<Applicant>): SearchResults<Applicant> => {
    const items = results.items.filter(appt => appt.id !== id);

    return {
      ...results,
      items,
      total: results.total + items.length - results.items.length,
    };
  };
}

function duplicateUcasCheck(ucasPersonalId: string) {
  return fetchWithMutex<SearchResults<Applicant>>({
    mutex: "duplicateCheck",

    request(dispatch, getState) {
      const id = dto(getState()).applicant.id;

      dispatch({ type: DUPLICATE_UCAS_START, ucasPersonalId });

      return api
        .searchApplicants({
          q: `ucas:${ucasPersonalId}`,
          count: 5,
        })
        .then(filterOutApplicant(id));
    },

    success(dispatch, getState, applicants) {
      dispatch({ type: DUPLICATE_UCAS_SUCCESS, applicants });
    },
  });
}

function duplicateStudentIdCheck(studentId: string) {
  return fetchWithMutex<SearchResults<Applicant>>({
    mutex: "duplicateCheck",

    request(dispatch, getState) {
      const id = dto(getState()).applicant.id;

      dispatch({ type: DUPLICATE_STUDENTID_START, studentId });

      return api
        .searchApplicants({
          q: `studentid:${studentId}`,
          count: 5,
        })
        .then(filterOutApplicant(id));
    },

    success(dispatch, getState, applicants) {
      dispatch({ type: DUPLICATE_STUDENTID_SUCCESS, applicants });
    },
  });
}

function duplicateEmailCheck(email: string) {
  return fetchWithMutex<SearchResults<Applicant>>({
    mutex: "duplicateCheck",

    request(dispatch, getState) {
      const id = dto(getState()).applicant.id;

      dispatch({ type: DUPLICATE_EMAIL_START, email });

      return api
        .searchApplicants({
          q: `email:${email}`,
          count: 5,
        })
        .then(filterOutApplicant(id));
    },

    success(dispatch, getState, applicants) {
      dispatch({ type: DUPLICATE_EMAIL_SUCCESS, applicants });
    },
  });
}

export function updateApplicantDetails(newDetails: ApplicantDetails) {
  return (dispatch: AppDispatch, getState: GetState) => {
    const oldDetails = dto(getState()).applicant.details;

    dispatch({ type: APPLICANT_DETAILS_UPDATE, details: newDetails });

    const newUcas = newDetails.ucasPersonalId;
    const oldUcas = oldDetails.ucasPersonalId;
    const newStudentId = newDetails.studentId;
    const oldStudentId = oldDetails.studentId;
    const newEmail = newDetails.email;
    const oldEmail = oldDetails.email;

    if (newUcas && newUcas.length >= 10 && newUcas !== oldUcas) {
      dispatch(duplicateUcasCheck(newUcas));
    }

    if (newStudentId && newStudentId.length >= 9 && newStudentId !== oldStudentId) {
      dispatch(duplicateStudentIdCheck(newStudentId));
    }

    if (newEmail && newEmail.length > 8 && newEmail !== oldEmail) {
      dispatch(duplicateEmailCheck(newEmail));
    }
  };
}

export function startEditingApplicantDetails(): MyAction {
  return { type: APPLICANT_DETAILS_EDIT_START };
}

export function cancelEditingApplicantDetails(): MyAction {
  return { type: APPLICANT_DETAILS_EDIT_CANCEL };
}

export function updateQualifications(qualifications: Qualifications): MyAction {
  return { type: QUALIFICATIONS_UPDATE, qualifications };
}

export function startEditingQualifications(): MyAction {
  return { type: QUALIFICATIONS_EDIT_START };
}

export function cancelEditingQualifications(): MyAction {
  return { type: QUALIFICATIONS_EDIT_CANCEL };
}

export function editApplication(): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    const application = selectedApplication(getState());
    if (application == null) {
      // Do nothing
      console.error("Application was null!");
    } else {
      dispatch({ type: APPLICATION_EDIT_START, application });
    }
  };
}

export function cancelEditingApplication(): AppAction {
  return { type: APPLICATION_EDIT_CANCEL };
}

export function setSelectedApplication(application: Application): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    return dispatch({ type: APPLICATION_SELECT, application });
  };
}

export function load(apptId: number, appnId?: Opt<number>) {
  return fetchWithMutex<ApplicantEditorDTO>({
    mutex: "applicantLoad",

    request(dispatch, getState) {
      return api.fetchApplicant(apptId);
    },

    pending(dispatch, getState) {
      dispatch({ type: LOADING });
    },

    success(dispatch, getState, dto) {
      const state = fromApplicantEditorDTO(dto);

      dispatch({ type: LOADED, dto: state });

      if (appnId != null) {
        const appn = state.applications.find(appn => appn.id === appnId);

        if (appn != null) {
          dispatch(setSelectedApplication(appn));
        }
      }
    },

    error: defaultErrorHandler,
  });
}

export function saveApplicantDetails() {
  return fetchWithMutex<Checked<ApplicantDetails>>({
    mutex: "saveApplicantDetails",

    request(dispatch, getState) {
      const appt = dto(getState()).applicant;
      dispatch({ type: APPLICANT_DETAILS_SAVE_START });
      return api.updateApplicantDetails(appt.id, appt.details);
    },

    success(dispatch, getState, checked) {
      if (checked.data) {
        toast.success("Applicant details saved");
        dispatch({
          type: APPLICANT_DETAILS_SAVE_SUCCESS,
          details: checked.data,
        });
      }

      if (checked.messages) {
        dispatch({
          type: SERVER_VALIDATION_FAILURE,
          messages: prefixMessages(checked.messages, ["applicant", "details"]),
        });
      }
    },

    error: validationErrorHandler(
      (error, messages) => ({
        type: SERVER_VALIDATION_FAILURE,
        error,
        messages,
      }),
      ["applicant", "details"],
    ),
  });
}

export function saveQualifications() {
  return fetchWithMutex<Checked<Qualifications>>({
    mutex: "saveQualifications",

    request(dispatch, getState) {
      const appt = dto(getState()).applicant;
      dispatch({ type: QUALIFICATIONS_SAVE_START });
      return api.updateQualifications(appt.id, appt.qualifications);
    },

    success(dispatch, getState, checked) {
      if (checked.data) {
        dispatch({
          type: QUALIFICATIONS_SAVE_SUCCESS,
          qualifications: checked.data,
        });
        toast.success("Qualifications saved");
      }

      if (checked.messages) {
        dispatch({
          type: SERVER_VALIDATION_FAILURE,
          messages: prefixMessages(checked.messages, ["applicant", "qualifications"]),
        });
      }
    },

    error: validationErrorHandler(
      (error, messages) => ({
        type: SERVER_VALIDATION_FAILURE,
        error,
        messages,
      }),
      ["applicant", "qualifications"],
    ),
  });
}

export function saveLabel(oldLabel: Label, newLabel: Label) {
  return fetchWithMutex<Label>({
    mutex: "saveLabel",

    request(dispatch, getState): Promise<Label> {
      return api.saveLabel(newLabel);
    },

    success(dispatch, getState, newLabel) {
      toast.success("Label updated");
      dispatch({
        type: LABEL_SAVE_SUCCESS,
        oldLabel,
        newLabel,
      });
    },

    error: defaultErrorHandler,
  });
}

export function fetchLabels() {
  return fetchWithMutex<Label[]>({
    mutex: "fetchLabels",

    request(dispatch, getState) {
      return api.fetchLabelsForApplicant(dto(getState()).applicant.id);
    },

    pending(dispatch, getState) {
      dispatch({ type: LABEL_FETCH_START });
    },

    success(dispatch, getState, labels) {
      dispatch({ type: LABEL_FETCH_SUCCESS, labels });
    },

    error: defaultErrorHandler,
  });
}

export function addApplicationInternal(choice: ApplicationChoice) {
  return fetchWithMutex<Application>({
    mutex: "addApplication",

    request(dispatch, getState) {
      dispatch({ type: APPLICATION_ADD_START });
      return api.blankApplication(choice);
    },

    success(dispatch, getState, application) {
      dispatch({
        type: APPLICATION_ADD_SUCCESS,
        application: { ...application },
      });
    },

    error: defaultErrorHandler,
  });
}

export function addApplication(choice: ApplicationChoice) {
  return (dispatch: AppDispatch, getState: GetState) => {
    const method = applicationChoiceToMethod(choice);
    const step1: Function = duplicateApplicationConfirmationModalCpsStep(
      dispatch,
      method,
      dto(getState()).applications,
      teams.currentTeam(getState()).code,
      programmeList.allProgrammes(getState()),
    );
    const step2: Function = () => dispatch(addApplicationInternal(choice));
    step1(() => step2());
  };
}

export function updateApplication(newApplication: Application): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    const oldApplication = selectedApplication(getState());

    if (oldApplication != null) {
      dispatch({
        type: APPLICATION_UPDATE,
        oldApplication,
        newApplication,
      });
    }
  };
}

function saveApplicationInternal(email: boolean) {
  return fetchWithMutex<Checked<Application>>({
    mutex: "saveApplication",

    request(dispatch, getState) {
      const id = dto(getState()).applicant.id;
      const index = myState(getState()).selectedApplicationIndex;
      const application = selectedApplication(getState());

      if (index == null || application == null) {
        return Promise.reject(new Error("Saving without editing?"));
      }

      dispatch({ type: APPLICATION_SAVE_START, application });

      return application.id < 0 ? api.createApplication(id, application, email) : api.updateApplication(application, email);
    },

    success(dispatch, getState, checked) {
      const details = dto(getState()).applicant.details;
      const newApplication: Opt<Application> = checked.data;

      if (newApplication != null) {
        const origApplication = myState(getState()).dtoInitial.applications.find(a => a.id === newApplication.id);

        const oldProgrammeCodes = dtoInitial(getState()).applications.map(appn => appn.programmeCode);

        if (applicationRequiresConfirmationEmail(newApplication, origApplication)) {
          if (email && details.email != null) {
            toast.success("Application saved. Email sent.");
          } else {
            toast.success("Application saved. Email not sent.");
          }
        } else {
          toast.success("Application saved.");
        }

        const oldApplication = selectedApplication(getState());

        if (oldApplication == null) {
          console.error("No old application", {
            oldApplication,
            newApplication,
          });
        } else {
          dispatch({
            type: APPLICATION_SAVE_SUCCESS,
            oldApplication,
            newApplication,
          });
        }

        dispatch(showApplicationScripts([newApplication]));

        if (!oldProgrammeCodes.includes(newApplication.programmeCode)) {
          dispatch(fetchLabels());
        }
      }

      const index = myState(getState()).selectedApplicationIndex;

      if (checked.messages && index) {
        dispatch({
          type: SERVER_VALIDATION_FAILURE,
          messages: prefixMessages(checked.messages, ["applications", index]),
        });
      }
    },

    // We have to wrap validationErrorHandler here
    // because we need the selectedApplicationIndex from the store:
    error(dispatch, getState, error) {
      const index = myState(getState()).selectedApplicationIndex;
      if (index) {
        return validationErrorHandler(
          (error, messages) => ({
            type: SERVER_VALIDATION_FAILURE,
            error,
            messages,
          }),
          ["applications", index],
        )(dispatch, getState, error);
      } else {
        return defaultErrorHandler(dispatch, getState, error);
      }
    },
  });
}

export function saveApplication() {
  return (dispatch: AppDispatch, getState: GetState) => {
    const appn = selectedApplication(getState());

    if (appn == null) {
      console.error("Attempt to save without an application");
      return;
    }

    const orig = myState(getState()).dtoInitial.applications.find(a => a.id === appn.id);

    const email = myState(getState()).dtoInitial.applicant.details.email;

    const step1 = applicationEmailConfirmationModalCpsStep(dispatch, email, appn, orig);

    step1(sendEmail => dispatch(saveApplicationInternal(sendEmail)));
  };
}

export function startProgrammeChange(newProgrammeCode: ProgrammeCode, interSchool: boolean): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    const oldApplication = selectedApplication(getState());

    if (oldApplication == null) {
      console.error("Attempt to start programme change without selected application");
      return;
    }

    if (oldApplication.programmeCode === newProgrammeCode) {
      return;
    }

    const defaultAction = () => {
      const newApplication: Application = {
        ...oldApplication,
        programmeCode: newProgrammeCode,
        oldProgrammeCode: null,
      };

      dispatch({
        type: APPLICATION_UPDATE,
        oldApplication,
        newApplication,
      });
    };

    const transitionAction = () => {
      const origApplication = applicationInitial(getState(), oldApplication.id);

      const newApplication = {
        ...oldApplication,
        programmeCode: newProgrammeCode,
        oldProgrammeCode: origApplication && (origApplication.oldProgrammeCode || origApplication.programmeCode),
      };

      dispatch({
        type: APPLICATION_UPDATE,
        oldApplication,
        newApplication,
      });

      dispatch(saveApplication());
    };

    const requiresChangeFormUnknown = () => {
      const origApplication = applicationInitial(getState(), oldApplication.id);

      dispatch(
        showModal("changeProgrammeConfirm", {
          onPrimaryClick: transitionAction,
          onSecondaryClick: defaultAction,
          oldProgrammeCode: origApplication && (origApplication.oldProgrammeCode || origApplication.programmeCode),
          newProgrammeCode: newProgrammeCode,
        }),
      );
    };

    const requiresChangeForm = () =>
      dispatch(
        showModal("changeProgramme", {
          onPrimaryClick: transitionAction,
        }),
      );

    if (oldApplication.id < 0) {
      return defaultAction();
    } else {
      switch (oldApplication.choice) {
        case ApplicationChoiceEnum.Clearing:
          return oldApplication.clearingDecision || interSchool ? requiresChangeForm() : requiresChangeFormUnknown();
        case ApplicationChoiceEnum.First:
        case ApplicationChoiceEnum.Second:
        case ApplicationChoiceEnum.Third:
        case ApplicationChoiceEnum.Fourth:
        case ApplicationChoiceEnum.Fifth:
          return requiresChangeForm();
        default:
          return defaultAction(); // pass through fine whatevz.
      }
    }
  };
}

export function completeProgrammeChange(): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    const application = selectedApplication(getState());

    if (!application || !application.oldProgrammeCode) return;
    dispatch({
      type: APPLICATION_COMPLETE_PROGRAMME_CHANGE,
      application,
    });

    dispatch(saveApplication());
  };
}

export function cancelProgrammeChange(): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    const application = selectedApplication(getState());

    if (!application || !application.oldProgrammeCode) return;

    dispatch({
      type: APPLICATION_CANCEL_PROGRAMME_CHANGE,
      application,
    });

    dispatch(saveApplication());
  };
}

export function removeApplication(application: Application): AppThunkAction {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({
      type: APPLICATION_REMOVE,
      application,
    });
  };
}

export function resendApplicationEmail(application: Application) {
  return fetchWithMutex({
    mutex: "resendApplicationEmail",

    request(dispatch, getState) {
      return api.resendApplicationEmail(application.id);
    },

    pending(dispatch, getState) {
      dispatch({ type: APPLICATION_EMAIL_RESEND_START });
    },

    success(dispatch, getState) {
      toast.success("Email resent");
      dispatch({ type: APPLICATION_EMAIL_RESEND_SUCCESS, application });
    },
  });
}

export function setHandoverInternal(appn: Application, handover: boolean, email: boolean) {
  return fetchWithMutex<Application>({
    mutex: "setHandover",

    request(dispatch, getState) {
      return api.setHandover(appn.id, handover, email);
    },

    pending(dispatch, getState) {
      dispatch({ type: SET_HANDOVER_START });
    },

    success(dispatch, getState, newApplication) {
      if (newApplication.handover) {
        toast.success("Handover complete");
      } else {
        toast.success("Handover reverted");
      }
      dispatch({
        type: SET_HANDOVER_SUCCESS,
        oldApplication: appn,
        newApplication,
      });
    },

    error: defaultErrorHandler,
  });
}

export function setHandover(appn: Application, handover: boolean) {
  return (dispatch: AppDispatch, getState: GetState) => {
    const step1 = handoverConfirmModalCpsStep(dispatch, handover, dto(getState()).applications);
    const step2 = handoverConfirmEmailModalCpsStep(dispatch, handover, dto(getState()).applicant.details.email);
    const step3 = (sendEmail: boolean) => dispatch(setHandoverInternal(appn, handover, sendEmail));

    step1(() => step2(sendEmail => step3(sendEmail)));
  };
}

export function addNote(author: User, schoolCode: Opt<SchoolCode>) {
  return function (dispatch: AppDispatch, getState: GetState) {
    dispatch({
      type: NOTE_ADD_SUCCESS,
      note: {
        applicantId: dto(getState()).applicant.id,
        authorUserId: author.id,
        authorName: `${author.forenames} ${author.surname}`,
        authorSchoolCode: author.defaultSchoolCode,
        schoolCode: schoolCode,
        text: "",
        timestamp: new Date().toISOString(),
        id: -1,
      },
    });
  };
}

export function updateNote(oldNote: Note, newNote: Note): MyAction {
  return { type: NOTE_UPDATE, oldNote, newNote };
}

export function saveNote(oldNote: Note, newNote: Note) {
  return fetchWithMutex<Checked<Note>>({
    mutex: "saveNote",

    request(dispatch, getState) {
      return oldNote.id === -1
        ? api.addNote(dto(getState()).applicant.id, {
            ...oldNote,
            text: newNote.text.trim(),
          })
        : api.updateNote({
            ...oldNote,
            text: newNote.text.trim(),
          });
    },

    success(dispatch, getState, checked) {
      if (checked.data) {
        toast.success("Note saved");
        dispatch({
          type: NOTE_SAVE_SUCCESS,
          oldNote,
          newNote: checked.data,
        });
      }

      if (checked.messages) {
        // TODO: Handle validation errors!
        throw new ValidationError(checked.messages);
      }
    },

    error: defaultErrorHandler,
  });
}

export function deleteNote(note: Note) {
  return fetchWithMutex({
    mutex: "deleteNote",

    request(dispatch, getState) {
      if (note.id < 0) {
        return Promise.resolve();
      }
      return api.deleteNote(note.id);
    },

    pending(dispatch, getState) {
      dispatch({ type: NOTE_DELETE_START });
    },

    success(dispatch, getState) {
      toast.success("Note deleted");
      dispatch({ type: NOTE_DELETE_SUCCESS, note });
    },
  });
}
