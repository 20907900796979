import { useQuery, useQueryClient } from "react-query";
import { actAs, stopActingAs, whoAmI } from "@qmspringboard/app/src/api";
import { Opt } from "@qmspringboard/app/src/utils";
import { User } from "@qmspringboard/app/src/model/types.generated";

const _queryKey = "whoAmI";

/** Refresh the user data from the server every minute. This is mainly so that changes to permissions are
 * reflected in the UI promptly. The load on the server won't be significant even if there are 500 users
 * logged in. We can easily handle 500 requests per minute.
 */
const _staleTimeout = 60 * 1000;

/**
 * Cache for a longer period, though, so that the UI won't have to wait for the http call to finish
 * before showing the UI. If the data is stale then react query will kick off the refresh in the background
 * and refresh the UI again when it's complete.
 */
const _cacheTimeout = 10 * 60 * 1000;

export const useWhoAmI = () => {
  const options = {
    staleTime: _staleTimeout,
    cacheTime: _cacheTimeout,
  };

  return useQuery(_queryKey, () => whoAmI(), options);
};

export const useCurrentUser: () => Opt<User> = () => {
  const { data } = useWhoAmI();
  if (data) {
    return data.actingAs ? data.actingAs : data.user;
  } else {
    return undefined;
  }
};

export const useActAs = () => {
  const queryClient = useQueryClient();
  return (username: string) => actAs(username).then(() => queryClient.invalidateQueries(_queryKey));
};

export const useStopActingAs = () => {
  const queryClient = useQueryClient();
  return () => stopActingAs().then(() => queryClient.invalidateQueries(_queryKey));
};

/**
 * Clear the current user data, forcing a refresh from the server
 */
export const useRefreshUserData = () => {
  useQueryClient().invalidateQueries(_queryKey);
};
