import type { PermissionType, User, UserRole } from "@qmspringboard/app/src/model/types.generated";
import { GlobalPermissionTypeEnum } from "@qmspringboard/shared/src/model/enums.generated";
import { Button, Container, Form, Grid, Header } from "semantic-ui-react";
import { FieldLabel, NullableTextInput } from "@qmspringboard/app/src/components/fields";
import React, { useState } from "react";
import { Opt } from "@qmspringboard/app/src/utils";

type ActAsFormProps = {
  loggedInUser: User;
  actingAsUser: Opt<User>;
  handleActAsClick: (username: string) => void;
  handleUnactAsClick: () => void;
};

export const ActAsForm = (props: ActAsFormProps) => {
  const [username, setUsername] = useState<Opt<string>>();

  const { loggedInUser, actingAsUser, handleActAsClick, handleUnactAsClick } = props;

  const canActAs: boolean = loggedInUser
    ? loggedInUser.roles.find(
        (userRole: UserRole) =>
          userRole.role.permissions.find(
            (perm: PermissionType) => perm === GlobalPermissionTypeEnum.SuperUser || perm === GlobalPermissionTypeEnum.CanActAsOtherUsers,
          ) != null,
      ) != null
    : false;

  return (
    <>
      {canActAs && (
        <Container>
          <Header as="h3">Act as another user</Header>

          {!actingAsUser && (
            <Form as="div" onSubmit={() => (username ? handleActAsClick(username) : null)}>
              <FieldLabel label="Enter a username...">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <NullableTextInput value={username} onChange={setUsername} fluid />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Button fluid onClick={() => (username ? handleActAsClick(username) : null)}>
                        Change
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FieldLabel>
            </Form>
          )}

          {actingAsUser && (
            <Container>
              <p>
                You are acting as {actingAsUser.forenames} {actingAsUser.surname} (<strong>{actingAsUser.username}</strong>
                ).
              </p>

              <Button onClick={handleUnactAsClick}>Restore</Button>
            </Container>
          )}
        </Container>
      )}
    </>
  );
};
