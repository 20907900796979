import React, { useState } from "react";
import { Container, Header, List } from "semantic-ui-react";
import styled from "styled-components";
import usePrevious from "../hooks/usePrevious";
import { schoolToTeamCode } from "../model/team";
import { diffArrays, joinEnglish } from "../utils/array";
import { InternalMessagesContext } from "./InternalMessageContext";
import { useApplicantViewers } from "@qmspringboard/app/src/hooks/useApplicantViewers";
import { ViewingUser } from "@qmspringboard/app/src/model/applicantviews.generated";

interface Props {
  applicantId: number;
}

const EllipsisListHeader = styled(List.Header)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal !important;
`;

const EllipsisListDescription = styled(List.Description)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0.25em 0;
  font-size: 0.9rem;
`;

function OtherViewers({ applicantId }: Props): React.ReactElement | null {
  const { addMessage } = React.useContext(InternalMessagesContext);
  const [messageShown, setMessageShown] = useState(false);

  const [otherViewerList, setOtherViewerList] = useState<Array<ViewingUser>>([]);

  // The server filters my user out of the list of viewers, so this will include only other viewers
  useApplicantViewers(applicantId, changeEvent => setOtherViewerList(changeEvent.viewers));

  const currMembers = otherViewerList;
  const prevMembers = usePrevious(otherViewerList);
  const [added, _removed] = diffArrays(prevMembers, currMembers, (x, y) => x.username === y.username);

  React.useEffect(() => {
    if (added.length > 0) {
      const id = new Date().getTime();

      const detail = [
        joinEnglish(added.map(member => `${member.name} (${schoolToTeamCode(member.school)})`)),
        added.length === 1 ? "is" : "are",
        "viewing this applicant",
      ].join(" ");

      addMessage({
        id,
        acknowledged: false,
        authorName: "",
        headline: "Other people are viewing this applicant",
        detail,
        timestamp: new Date().toISOString(),
      });
      setMessageShown(true);
    }
  }, [addMessage, added, messageShown, otherViewerList]);

  if (otherViewerList.length === 0) {
    return <p>No other people are viewing this applicant</p>;
  }

  return (
    <List>
      {otherViewerList.map(viewer => (
        <List.Item key={viewer.username}>
          <EllipsisListHeader>
            {viewer.name} ({schoolToTeamCode(viewer.school)})
          </EllipsisListHeader>
          <EllipsisListDescription>
            <a href={`mailto:${viewer.email}`}>{viewer.email}</a>
          </EllipsisListDescription>
        </List.Item>
      ))}
    </List>
  );
}

export function ApplicantOtherViewers({ applicantId }: Props): React.ReactElement {
  return (
    <Container>
      <Header as="h3" dividing>
        Other Viewers
      </Header>
      <OtherViewers applicantId={applicantId} />
    </Container>
  );
}
