import { createSelector } from "reselect";
import * as api from "../../api";
import * as pot from "../../api/pot";
import * as toast from "../../components/toast";
import { AppError, UnauthorizedError } from "../../errors";
import { BearerAuthentication, User } from "../../model/types";
import { AppDispatch, AppReducer } from "../actions";
import { defaultErrorHandler, fetchWithMutex } from "../fetch";
import { AppState } from "../state";
import { initialState, MyState } from "./state";

export type Identity = {
  username: string;
};

const AUTH_START = "auth/AUTH_START"; // issue a check to the server for a bearer token
const AUTH_SUCCESS = "auth/AUTH_SUCCESS"; // bearer token returned
const ACT_AS_START = "auth/ACT_AS_START"; // become another user (CanActAsOtherUsers permission only)
const ACT_AS_SUCCESS = "auth/ACT_AS_SUCCESS"; // become another user (CanActAsOtherUsers permission only)
const ACT_AS_FAILURE = "auth/ACT_AS_FAILURE"; // become another user (CanActAsOtherUsers permission only)
const UNACT_AS = "auth/UNACT_AS"; // unbecome another user
export const LOGOUT = "auth/LOGOUT";

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return {
        bearer: pot.pending(action.requestId),
        loggedInUser: pot.pending(action.requestId),
        actingAsUser: pot.empty(),
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        bearer: pot.ready(action.bearer),
        loggedInUser: pot.ready(action.user),
      };
    case ACT_AS_START:
      return {
        ...state,
        actingAsUser: pot.pending(action.requestId),
      };
    case ACT_AS_SUCCESS:
      return {
        ...state,
        actingAsUser: pot.ready(action.user),
      };
    case ACT_AS_FAILURE:
      return {
        ...state,
        actingAsUser: pot.failed(action.error),
      };
    case UNACT_AS:
      return {
        ...state,
        actingAsUser: pot.empty(),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

///////////////
// Selectors //
///////////////

export const myState = (state: AppState): MyState => state.auth;

export const bearerAuthentication: (state: AppState) => BearerAuthentication | null = createSelector(myState, myState => pot.get(myState.bearer));

export const bearerToken: (state: AppState) => string | null = createSelector(myState, myState =>
  pot.get(pot.map(myState.bearer, bearer => bearer.bearerToken)),
);

export const currentUser: (state: AppState) => User | null = createSelector(myState, state =>
  pot.get(pot.orElse(state.actingAsUser, state.loggedInUser)),
);

export const authError: (state: AppState) => AppError | null = createSelector(
  myState,
  state => pot.error(state.actingAsUser) || pot.error(state.loggedInUser),
);

/////////////////////
// Action creators //
/////////////////////

export function checkAuthentication() {
  return fetchWithMutex<{ bearer: BearerAuthentication; user: User }>({
    mutex: "checkAuthentication",

    request(dispatch, getState) {
      return api.fetchBearerAuthentication().then(bearer => {
        return api.fetchUser(bearer.userId).then(user => {
          return { bearer, user };
        });
      });
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: AUTH_START, requestId });
    },

    success(dispatch, getState, { bearer, user }) {
      toast.success(`Welcome back, ${user.forenames}!`);
      dispatch({ type: AUTH_SUCCESS, bearer, user });
    },

    // The default error handler triggers error actions:
    error: defaultErrorHandler,
  });
}

export function refresh() {
  return fetchWithMutex<{ bearer: BearerAuthentication; user: User }>({
    mutex: "checkAuthentication",

    request(dispatch, getState) {
      const bearer = bearerAuthentication(getState());

      return bearer
        ? api.fetchUser(bearer.userId).then(user => {
            return { bearer, user };
          })
        : Promise.reject(new UnauthorizedError("No bearer information stored in client"));
    },

    success(dispatch, getState, { bearer, user }) {
      dispatch({ type: AUTH_SUCCESS, bearer, user });
    },

    // The default error handler triggers error actions:
    error: defaultErrorHandler,
  });
}

export interface LogoutParams {
  showToast?: boolean;
  clearRedirect?: boolean;
}

export function logout(params: LogoutParams = {}) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: LOGOUT });
    params.showToast !== false && toast.success("You have logged out");
    window.location.href = api.loginUrl(params.clearRedirect ? "/" : (window.location.pathname || "/") + (window.location.search || ""));
  };
}

export const fetching = (state: AppState) => pot.fetching(myState(state).loggedInUser);
