import { useBlankApplicant } from "@qmspringboard/app/src/hooks/useBlankApplicant";
import { useCurrentTeam } from "@qmspringboard/app/src/reducers/teams";
import { push } from "connected-react-router";
import React from "react";
import { ApplicantCreatePage } from "@qmspringboard/app/src/pages/ApplicantCreatePage/ApplicantCreatePage";
import { useLocation } from "react-router";
import { locationQueryParams } from "@qmspringboard/app/src/utils/queryParams";
import { regexSearch } from "@qmspringboard/app/src/utils";
import usePermissions from "@qmspringboard/app/src/hooks/usePermissions";
import { useAllTeams } from "@qmspringboard/app/src/hooks/useAllSchools";
import { connect } from "react-redux";
import { useCurrentUser } from "@qmspringboard/app/src/hooks/useWhoAmI";

/**
 * Wait for a blank applicant to load before displaying the ApplicantCreatePage
 */
const InitialState = ({ push }: { push: (url: string) => void }): JSX.Element => {
  const currentUser = useCurrentUser();
  const currentTeam = useCurrentTeam();
  const permissions = usePermissions();
  const teams = useAllTeams();

  const blankApplicant = useBlankApplicant();

  const location = useLocation();
  const { q } = locationQueryParams(location);
  const initialUcas: string | null = q ? regexSearch(/ucas:([0-9]{1,10})/i, /([0-9]{10})/i)(q) : null;

  const initialEmail: string | null = q ? regexSearch(/email:([^ ]+)/i, /([^@ ]+[@][^@ ]+)/i)(q) : null;

  /**
   * On the Take A Call page the hotline operator can search for an existing applicant, and
   * if they then hit "Add a new Applicant" then the search query is added to the /application/new
   * url. This code will pick up that search query (as param with name 'q') and look for search
   * terms of the form 'ucas:nnnnn' and 'email:xxx@yyy.zzz'. If found, they will be used to
   * initialise the relevant properties of the blank applicant.
   */
  const initialApplicant = blankApplicant && {
    ...blankApplicant,
    applicant: {
      ...blankApplicant.applicant,
      details: {
        ...blankApplicant.applicant.details,
        ucasPersonalId: initialUcas,
        email: initialEmail,
      },
    },
  };

  if (initialApplicant) {
    return (
      <ApplicantCreatePage
        push={push}
        blankApplicant={initialApplicant}
        currentUser={currentUser}
        currentTeam={currentTeam}
        permissions={permissions}
        allTeams={teams}
      />
    );
  }
  return <></>;
};

export const noState = () => {
  return {};
};

// The push function still needs to be wired for dispatch via redux. Not sure how to eliminate this
export default connect(noState, { push })(InitialState);
