/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Tagged, unsafeIsTagged } from "@qmspringboard/shared/dist/model/tagged";
import { Programme, isProgramme } from "./programmes.generated";
import { SchoolCode, isSchoolCode } from "@qmspringboard/shared/dist/model/core.generated";
import {
  RequirementsCheckResults,
  isRequirementsCheckResults,
  RequirementsCheckStatus,
  isRequirementsCheckStatus,
} from "@qmspringboard/shared/dist/model/requirements.generated";

export type SearchExpr = string;

export type SortOrder = string;

export interface RequirementsCheckDTO {
  programme: Programme;
  results: RequirementsCheckResults;
  overallStatus?: RequirementsCheckStatus | null;
}

export interface PredictionOverride {
  prediction: number;
  predictionExpiry: string;
}

export interface TelephoneScript {
  title: string;
  content: string;
}

export interface Previews<A> {
  complete: A;
  incomplete: A;
}

export interface BearerAuthentication {
  bearerToken: string;
  userId: number;
  expiresAt: string;
}

export type OfferScriptType =
  | "clearingVerbalOfferScript"
  | "clearingPreReleaseVerbalOfferScript"
  | "clearingWaitingListScript"
  | "clearingInterviewScript"
  | "clearingDeclinedOrRejectedScript";

export type EmailType =
  | "clearingVerbalOffer"
  | "clearingPreReleaseVerbalOffer"
  | "clearingWaitingList"
  | "clearingInterview"
  | "clearingRejected"
  | "programmeClosed"
  | "handover";

export type GlobalPermissionType =
  | "SuperUser"
  | "CanActAsOtherUsers"
  | "CanLogIn"
  | "CanUpdateSchools"
  | "CanUpdateUsers"
  | "CanSyncSelectorList"
  | "CanTransferApplicants"
  | "CanCreateApplicants"
  | "CanUpdateApplicantDetails"
  | "CanUpdateQualifications"
  | "CanUpdateNonALevelQualifications"
  | "CanMakeHotlineClearingOffers"
  | "CanUpdateProgrammes"
  | "CanUpdateAdmissionsLabelPrototypes"
  | "CanUpdateAdmissionsLabels"
  | "CanAddAnnouncements"
  | "CanUpdateAdmissionsNotes"
  | "CanMakeInterSchoolChangesOfProgramme"
  | "CanUpdateAdmissionsSavedSearches"
  | "CanUpdateAdmissionsTargets"
  | "CanExportSPOData"
  | "CanExportTransferGroups"
  | "CanImportUcasClearingPlusApplicants"
  | "CanViewInternationalEquivsTable";

export type SchoolPermissionType =
  | "CanUpdateSchoolConfig"
  | "CanUpdateSchoolDeadlines"
  | "CanUpdateClassifierRules"
  | "CanUpdateTelephoneScripts"
  | "CanUpdateEmailTemplates"
  | "CanUpdateReportingGroups"
  | "CanUpdateSchoolLabelPrototypes"
  | "CanUpdateSchoolSavedSearches"
  | "CanPutOnClearingWaitingList"
  | "CanMakeClearingOffers"
  | "CanRejectClearingApplications"
  | "CanAcceptClearingApplications"
  | "CanInterviewApplications"
  | "CanUpdateUcasApplications"
  | "CanUpdateCocoStatuses"
  | "CanUpdateUcasSitsFields"
  | "CanUpdateSchoolLabels"
  | "CanUpdateSchoolNotes"
  | "CanMakeOffersOnClosedProgrammes"
  | "CanUpdatePredictionOverrides"
  | "CanHandoverApplications";

export type PermissionType = GlobalPermissionType | SchoolPermissionType;

export interface Permission {
  type: PermissionType;
  school?: string | null;
}

export interface UserRole {
  role: Role;
  schools: SchoolCode[];
}

export interface Role {
  name: string;
  permissions: PermissionType[];
  id: number;
}

export interface User {
  username: string;
  surname: string;
  forenames: string;
  email: string;
  roles: UserRole[];
  defaultSchoolCode?: SchoolCode | null;
  canLogin: boolean;
  id: number;
}

export interface WhoAmIResponse {
  user: User;
  actingAs?: User | null;
}

export interface School {
  code: SchoolCode;
  name: string;
  facultyCode: string;
  autopilot: boolean;
}

export interface SavedSearch {
  schoolCode?: SchoolCode | null;
  title: string;
  searchString: string;
  id: number;
}

export interface ClearingConfig {
  start: string;
  end: string;
  warnOnUsage: boolean;
}

export interface FeatureFlags {
  environmentFriendlyName: string;
  clearingConfig?: ClearingConfig | null;
  showWatermark: boolean;
  clickToCallEnabled: boolean;
}

export const isSearchExpr = (v: any): v is SearchExpr => {
  return typeof v === "string";
};

export const isSortOrder = (v: any): v is SortOrder => {
  return typeof v === "string";
};

export const isRequirementsCheckDTO = (v: any): v is RequirementsCheckDTO => {
  return (
    typeof v === "object" &&
    v != null &&
    "programme" in v &&
    isProgramme(v.programme) &&
    "results" in v &&
    isRequirementsCheckResults(v.results) &&
    (!("overallStatus" in v) || isRequirementsCheckStatus(v.overallStatus) || v.overallStatus === null)
  );
};

export const isPredictionOverride = (v: any): v is PredictionOverride => {
  return (
    typeof v === "object" &&
    v != null &&
    "prediction" in v &&
    typeof v.prediction === "number" &&
    "predictionExpiry" in v &&
    typeof v.predictionExpiry === "string"
  );
};

export const isTelephoneScript = (v: any): v is TelephoneScript => {
  return typeof v === "object" && v != null && "title" in v && typeof v.title === "string" && "content" in v && typeof v.content === "string";
};

export const isPreviews =
  <A>(isA: (a: any) => a is A) =>
  (v: any): v is Previews<A> => {
    return typeof v === "object" && v != null && "complete" in v && isA(v.complete) && "incomplete" in v && isA(v.incomplete);
  };

export const isBearerAuthentication = (v: any): v is BearerAuthentication => {
  return (
    typeof v === "object" &&
    v != null &&
    "bearerToken" in v &&
    typeof v.bearerToken === "string" &&
    "userId" in v &&
    typeof v.userId === "number" &&
    "expiresAt" in v &&
    typeof v.expiresAt === "string"
  );
};

export const isOfferScriptType = (v: any): v is OfferScriptType => {
  return (
    v === "clearingVerbalOfferScript" ||
    v === "clearingPreReleaseVerbalOfferScript" ||
    v === "clearingWaitingListScript" ||
    v === "clearingInterviewScript" ||
    v === "clearingDeclinedOrRejectedScript"
  );
};

export const isEmailType = (v: any): v is EmailType => {
  return (
    v === "clearingVerbalOffer" ||
    v === "clearingPreReleaseVerbalOffer" ||
    v === "clearingWaitingList" ||
    v === "clearingInterview" ||
    v === "clearingRejected" ||
    v === "programmeClosed" ||
    v === "handover"
  );
};

export const isGlobalPermissionType = (v: any): v is GlobalPermissionType => {
  return (
    v === "SuperUser" ||
    v === "CanActAsOtherUsers" ||
    v === "CanLogIn" ||
    v === "CanUpdateSchools" ||
    v === "CanUpdateUsers" ||
    v === "CanSyncSelectorList" ||
    v === "CanTransferApplicants" ||
    v === "CanCreateApplicants" ||
    v === "CanUpdateApplicantDetails" ||
    v === "CanUpdateQualifications" ||
    v === "CanUpdateNonALevelQualifications" ||
    v === "CanMakeHotlineClearingOffers" ||
    v === "CanUpdateProgrammes" ||
    v === "CanUpdateAdmissionsLabelPrototypes" ||
    v === "CanUpdateAdmissionsLabels" ||
    v === "CanAddAnnouncements" ||
    v === "CanUpdateAdmissionsNotes" ||
    v === "CanMakeInterSchoolChangesOfProgramme" ||
    v === "CanUpdateAdmissionsSavedSearches" ||
    v === "CanUpdateAdmissionsTargets" ||
    v === "CanExportSPOData" ||
    v === "CanExportTransferGroups" ||
    v === "CanImportUcasClearingPlusApplicants" ||
    v === "CanViewInternationalEquivsTable"
  );
};

export const isSchoolPermissionType = (v: any): v is SchoolPermissionType => {
  return (
    v === "CanUpdateSchoolConfig" ||
    v === "CanUpdateSchoolDeadlines" ||
    v === "CanUpdateClassifierRules" ||
    v === "CanUpdateTelephoneScripts" ||
    v === "CanUpdateEmailTemplates" ||
    v === "CanUpdateReportingGroups" ||
    v === "CanUpdateSchoolLabelPrototypes" ||
    v === "CanUpdateSchoolSavedSearches" ||
    v === "CanPutOnClearingWaitingList" ||
    v === "CanMakeClearingOffers" ||
    v === "CanRejectClearingApplications" ||
    v === "CanAcceptClearingApplications" ||
    v === "CanInterviewApplications" ||
    v === "CanUpdateUcasApplications" ||
    v === "CanUpdateCocoStatuses" ||
    v === "CanUpdateUcasSitsFields" ||
    v === "CanUpdateSchoolLabels" ||
    v === "CanUpdateSchoolNotes" ||
    v === "CanMakeOffersOnClosedProgrammes" ||
    v === "CanUpdatePredictionOverrides" ||
    v === "CanHandoverApplications"
  );
};

export const isPermissionType = (v: any): v is PermissionType => {
  return isGlobalPermissionType(v) || isSchoolPermissionType(v);
};

export const isPermission = (v: any): v is Permission => {
  return (
    typeof v === "object" &&
    v != null &&
    "type" in v &&
    isPermissionType(v.type) &&
    (!("school" in v) || typeof v.school === "string" || v.school === null)
  );
};

export const isUserRole = (v: any): v is UserRole => {
  return (
    typeof v === "object" &&
    v != null &&
    "role" in v &&
    isRole(v.role) &&
    "schools" in v &&
    Array.isArray(v.schools) &&
    v.schools.every((i: any) => isSchoolCode(i))
  );
};

export const isRole = (v: any): v is Role => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "permissions" in v &&
    Array.isArray(v.permissions) &&
    v.permissions.every((i: any) => isPermissionType(i)) &&
    "id" in v &&
    typeof v.id === "number"
  );
};

export const isUser = (v: any): v is User => {
  return (
    typeof v === "object" &&
    v != null &&
    "username" in v &&
    typeof v.username === "string" &&
    "surname" in v &&
    typeof v.surname === "string" &&
    "forenames" in v &&
    typeof v.forenames === "string" &&
    "email" in v &&
    typeof v.email === "string" &&
    "roles" in v &&
    Array.isArray(v.roles) &&
    v.roles.every((i: any) => isUserRole(i)) &&
    (!("defaultSchoolCode" in v) || isSchoolCode(v.defaultSchoolCode) || v.defaultSchoolCode === null) &&
    "canLogin" in v &&
    typeof v.canLogin === "boolean" &&
    "id" in v &&
    typeof v.id === "number"
  );
};

export const isWhoAmIResponse = (v: any): v is WhoAmIResponse => {
  return typeof v === "object" && v != null && "user" in v && isUser(v.user) && (!("actingAs" in v) || isUser(v.actingAs) || v.actingAs === null);
};

export const isSchool = (v: any): v is School => {
  return (
    typeof v === "object" &&
    v != null &&
    "code" in v &&
    isSchoolCode(v.code) &&
    "name" in v &&
    typeof v.name === "string" &&
    "facultyCode" in v &&
    typeof v.facultyCode === "string" &&
    "autopilot" in v &&
    typeof v.autopilot === "boolean"
  );
};

export const isSavedSearch = (v: any): v is SavedSearch => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("schoolCode" in v) || isSchoolCode(v.schoolCode) || v.schoolCode === null) &&
    "title" in v &&
    typeof v.title === "string" &&
    "searchString" in v &&
    typeof v.searchString === "string" &&
    "id" in v &&
    typeof v.id === "number"
  );
};

export const isClearingConfig = (v: any): v is ClearingConfig => {
  return (
    typeof v === "object" &&
    v != null &&
    "start" in v &&
    typeof v.start === "string" &&
    "end" in v &&
    typeof v.end === "string" &&
    "warnOnUsage" in v &&
    typeof v.warnOnUsage === "boolean"
  );
};

export const isFeatureFlags = (v: any): v is FeatureFlags => {
  return (
    typeof v === "object" &&
    v != null &&
    "environmentFriendlyName" in v &&
    typeof v.environmentFriendlyName === "string" &&
    (!("clearingConfig" in v) || isClearingConfig(v.clearingConfig) || v.clearingConfig === null) &&
    "showWatermark" in v &&
    typeof v.showWatermark === "boolean" &&
    "clickToCallEnabled" in v &&
    typeof v.clickToCallEnabled === "boolean"
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable no-mixed-operators */
