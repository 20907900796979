import type { User } from "@qmspringboard/app/src/model/types.generated";
import { Table } from "semantic-ui-react";
import RolesView from "@qmspringboard/app/src/components/RolesView";
import React from "react";

export const UserView = (props: { user: User }) => {
  const user = props.user;
  return (
    <Table columns="2" definition>
      <Table.Body>
        <Table.Row>
          <Table.Cell verticalAlign="top">Name</Table.Cell>
          <Table.Cell verticalAlign="top">
            {user.forenames} {user.surname}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell verticalAlign="top">Username</Table.Cell>
          <Table.Cell verticalAlign="top">{user.username}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell verticalAlign="top">Email</Table.Cell>
          <Table.Cell verticalAlign="top">{user.email}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell verticalAlign="top">Roles</Table.Cell>
          <Table.Cell verticalAlign="top">
            <RolesView roles={user.roles} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
