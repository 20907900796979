import { applicationChoiceToMethod } from "@qmspringboard/shared/dist/model/application";
import {
  isClosedToFeeCategory,
  isCompletelyClosed,
  isCompletelyFull,
  isFullToFeeCategory,
  spacesDescription,
} from "@qmspringboard/shared/dist/model/programme";
import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import lodash, { flowRight } from "lodash";
import React, { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { Button, Dropdown, Grid, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useRequirementsCheckDTOs } from "../hooks/useRequirementsChecks";
import { Qualifications } from "../model/applicant";
import { ApplicationChoiceEnum, FeeCategory, OfferStatusEnum } from "../model/enums";
import { Messages } from "../model/errors";
import { Permissions } from "../model/permission";
import { ProgrammeCode, programmeCodeToUcasCourseCode, unsafeStringToProgrammeCode } from "../model/programme";
import { Application, ApplicationChoice, Programme, RequirementsCheckDTO, SchoolCode } from "../model/types";
import { Opt } from "../utils";
import { ShowModalFunc, withModal } from "../utils/modal";
import ClosedProgrammeCheckView from "./ClosedProgrammeCheckView";
import { DropdownField, FieldLabel } from "./fields";
import { DropdownOptions } from "./fields/DropdownField";
import RequirementsCheckView from "./RequirementsCheckView";
import usePermissions from "@qmspringboard/app/src/hooks/usePermissions";

interface ProgrammeSelectionEditorProps {
  allProgrammes: Programme[];
  showModal: ShowModalFunc;
  qualifications: Qualifications;
  guessedFeeCategory: FeeCategory | null;
  showApplicationChoice?: boolean;
  onChange: Function;
  readOnly: boolean;
  value: Application;
  initialValue: Opt<Application>;
  messages: Messages;
  startProgrammeChange: (code: ProgrammeCode, interSchool: boolean) => void;
  completeProgrammeChange: () => void;
  cancelProgrammeChange: () => void;
}

const CompactRow = styled(Grid.Row)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const FloatedIcon = styled(Icon)`
  float: right;
  margin-right: 10px;
`;

// HACK: Replace this in 2022 with a flag Programme.visibleToHotlineUsers.
// This is the only R900 programme that should be visible to hotline users.
// We filter out other programmes so the user can't see/select them.
const hotlineR900ProgrammeCode = unsafeStringToProgrammeCode("R900-USMLN");

function canSeeProgramme(prog: Programme, selectedProgrammeCode: ProgrammeCode, canMakeOffersOnAllR900Routes: boolean): boolean {
  const code = prog.code;

  return canMakeOffersOnAllR900Routes || code === selectedProgrammeCode || code === hotlineR900ProgrammeCode || !code.startsWith("R900");
}

function canMakeOffers(programme: Programme, guessedFeeCategory: FeeCategory | null, permissions: Permissions): boolean {
  return isClosedToFeeCategory(programme, guessedFeeCategory) || isFullToFeeCategory(programme, guessedFeeCategory)
    ? permissions.canMakeOffersOnClosedProgrammes(programme.schoolCode)
    : true;
}

function showProgramme(
  applicantId: number,
  choice: ApplicationChoice,
  programmeCode: ProgrammeCode,
  oldProgrammeCode: Opt<ProgrammeCode>,
  permissions: Permissions,
  schoolCode: Opt<string>,
  interSchool: boolean = false,
) {
  return function ({ programme }: RequirementsCheckDTO): boolean {
    const method = applicationChoiceToMethod(choice);

    if (interSchool) {
      // If these are options for an inter-school CoP dialog,
      // show ALL TEH PROGRAMMEZ!
      return true;
    } else if (programmeCode === programme.code) {
      // Always show the currently selected programme:
      return true;
    } else if (oldProgrammeCode != null) {
      // If we're already changing programmes,
      // don't show any other programmes in the dropdown:
      return false;
    } else if (applicantId == null || applicantId < 0) {
      // If the application is unsaved,
      // allow the user to select any program for which we have permission:
      return permissions.canSeeProgrammeInProgrammeSelect(method, programme);
    } else {
      // If the application is saved,
      // only allow the user to select programmes in the same school
      // (we have a separate dialog for inter-school CoPs):
      return programme.schoolCode === schoolCode && permissions.canSeeProgrammeInProgrammeSelect(method, programme);
    }
  };
}

function calcProgrammeOptions(
  applicantId: number,
  guessedFeeCategory: FeeCategory | null,
  choice: ApplicationChoice,
  programmeCode: ProgrammeCode,
  oldProgrammeCode: Opt<ProgrammeCode>,
  allProgrammesWithResults: RequirementsCheckDTO[],
  permissions: Permissions,
  schoolCode: Opt<SchoolCode>,
  interSchool: boolean = false,
): DropdownOptions<Opt<RequirementsCheckDTO>> {
  const canMakeOffersOnAllR900Routes = permissions.canMakeOffersOnAllR900Routes();

  return lodash
    .chain(allProgrammesWithResults)
    .filter(showProgramme(applicantId, choice, programmeCode, oldProgrammeCode, permissions, schoolCode, interSchool))
    .flatMap((value: RequirementsCheckDTO) => {
      const { programme, overallStatus } = value;

      const visible: boolean = canSeeProgramme(programme, programmeCode, canMakeOffersOnAllR900Routes);

      if (!visible) {
        return [];
      } else {
        const completelyClosed = isCompletelyClosed(programme);
        const completelyFull = isCompletelyFull(programme);

        // Do we advise new offers?
        // - we don't if the programme is closed or full, or if the applicant didn't make the grades;
        // - we do if the programme has spaces and the applicant made the grades;
        // - we advise caution if the programme has spaces but:
        //   - we can't guess the applicant's fee status;
        //   - we can't analyse the applicant's grades.
        const adviseNewOffers: "yes" | "no" | "caution" =
          completelyClosed || completelyFull ? "no" : overallStatus === "Passed" ? "yes" : overallStatus === "Failed" ? "no" : "caution";

        // Do we allow new offers?
        // - this is the same as adviseNewOffers...
        // - except certain users can still make offers when the programme is closed...
        // - and all users can make offers on programmes with spaces, even if the applicant has low grades.
        const allowNewOffers: boolean = canMakeOffers(programme, guessedFeeCategory, permissions);

        const label = [
          programme.schoolCode,
          "-",
          programmeCodeToUcasCourseCode(programme.code),
          programme.name,
          completelyFull
            ? "(FULL)"
            : completelyClosed
              ? "(CLOSED)"
              : programme.isClosedHome || programme.isFullHome
                ? "(OVERSEAS ONLY)"
                : programme.isClosedOverseas || programme.isFullOverseas
                  ? "(HOME ONLY)"
                  : "(SPACES)",
        ].join(" ");

        let icon: ReactElement;
        switch (adviseNewOffers) {
          case "yes":
            icon = <FloatedIcon name="check" color="green" />;
            break;
          case "no":
            icon = <FloatedIcon name="close" color="red" />;
            break;
          case "caution":
            icon = <FloatedIcon name="question" color="grey" />;
            break;
          default:
            checkExhausted(adviseNewOffers);
        }

        const content: ReactNode = (
          <Dropdown.Item>
            {icon}
            {label}
            {programme.keywords && <br />}
            {programme.keywords && <span style={{ color: "#aaa" }}>{programme.keywords}</span>}
          </Dropdown.Item>
        );

        const active: boolean = programme.code === programmeCode;

        const disabled: boolean = !allowNewOffers;

        const searchTerms: string[] = [
          programme.schoolCode,
          programme.code,
          programme.name,
          ...(programme.keywords == null ? [] : [programme.keywords]),
        ];

        let sortOrder: number;
        switch (adviseNewOffers) {
          case "yes":
            sortOrder = 0;
            break;
          case "caution":
            sortOrder = 1;
            break;
          case "no":
            sortOrder = 2;
            break;
          default:
            checkExhausted(adviseNewOffers);
        }

        return [
          {
            dropdownItem: {
              value,
              label,
              content,
              active,
              disabled,
              selectable: !disabled,
              searchTerms,
            },
            sortOrder,
          },
        ];
      }
    })
    .sortBy(({ sortOrder }) => sortOrder)
    .map(({ dropdownItem }) => dropdownItem)
    .value();
}

function programmeSearch(options: DropdownOptions<Opt<RequirementsCheckDTO>>, query: string): DropdownOptions<Opt<RequirementsCheckDTO>> {
  const regex = new RegExp(lodash.escapeRegExp(query), "gi");
  return options.filter(
    opt =>
      opt.value != null &&
      (regex.test(opt.value.programme.code) ||
        regex.test(opt.value.programme.schoolCode) ||
        regex.test(opt.value.programme.name) ||
        (opt.value.programme.keywords && regex.test(opt.value.programme.keywords))),
  );
}

function ProgrammeSelectionEditor(props: ProgrammeSelectionEditorProps) {
  const {
    value,
    initialValue,
    onChange,
    readOnly,
    allProgrammes,
    qualifications,
    guessedFeeCategory,
    showApplicationChoice,
    messages,
    startProgrammeChange,
    showModal,
  } = props;

  const permissions = usePermissions();

  const allProgrammesWithResults = useRequirementsCheckDTOs();

  const programmeWithResults: Opt<RequirementsCheckDTO> = useMemo(
    () => allProgrammesWithResults?.find(option => option.programme.code === value.programmeCode),
    [allProgrammesWithResults, value.programmeCode],
  );

  const programme: Opt<Programme> = useMemo(() => programmeWithResults?.programme, [programmeWithResults]);

  const initialProgramme: Opt<Programme> = useMemo(
    () => (initialValue == null ? null : allProgrammesWithResults?.find(option => option.programme.code === initialValue.programmeCode)?.programme),
    [allProgrammesWithResults, initialValue],
  );

  // const checkResults: Opt<RequirementsCheckList> = useMemo(
  //   () => programmeWithResults?.checkResults,
  //   [programmeWithResults],
  // );

  const schoolCode: Opt<SchoolCode> = useMemo(() => programme?.schoolCode, [programme]);

  const transitioning: boolean = !!value.oldProgrammeCode;

  const oldProgramme: Opt<Programme> = useMemo(
    () => (transitioning ? allProgrammes.find(prog => prog.code === value.oldProgrammeCode) : null),
    [allProgrammes, transitioning, value.oldProgrammeCode],
  );

  const programmeOptions = useMemo(
    () =>
      calcProgrammeOptions(
        value.applicantId,
        guessedFeeCategory,
        value.choice,
        value.programmeCode,
        value.oldProgrammeCode,
        allProgrammesWithResults ?? [],
        permissions,
        schoolCode,
        false,
      ),
    [
      value.applicantId,
      guessedFeeCategory,
      value.choice,
      value.programmeCode,
      value.oldProgrammeCode,
      allProgrammesWithResults,
      permissions,
      schoolCode,
    ],
  );

  const isInviteToInterviewStatus: boolean = value.offerStatus === OfferStatusEnum.Interview;

  // Show the inter-school button when:
  // - the application is saved, and
  // - the user is permitted to make interschool change of programmes, and
  // - it's not Invite to Interview (I) (as these are only in SMD so there's no need for inter-school controls, in 2019)
  const showInterSchoolChangeOfProgrammeButton: boolean = useMemo(
    () => value.id >= 0 && permissions.canMakeInterSchoolChangesOfProgramme() && !isInviteToInterviewStatus,
    [isInviteToInterviewStatus, permissions, value.id],
  );

  const ucasApplicationChoiceOptions: DropdownOptions<ApplicationChoice> = ApplicationChoiceEnum.dropdownOptions().filter(
    item => item.value !== ApplicationChoiceEnum.Clearing,
  );

  const handleStartProgrammeChange = useCallback(
    (programmeCode: ProgrammeCode, interSchool: boolean) => {
      if (startProgrammeChange) {
        startProgrammeChange(programmeCode, interSchool);
      } else {
        onChange({ ...value, programmeCode });
      }
    },
    [onChange, startProgrammeChange, value],
  );

  const showInterSchoolChangeOfProgramme = useCallback(() => {
    showModal("interSchoolChangeOfProgramme", {
      title: "Inter-school change of programme",
      options: calcProgrammeOptions(
        value.applicantId,
        guessedFeeCategory,
        value.choice,
        value.programmeCode,
        value.oldProgrammeCode,
        allProgrammesWithResults ?? [],
        permissions,
        schoolCode,
        true,
      ),
      initialValue: value.programmeCode,
      placeholder: "Programmes",
      onPrimaryClick: (programmeCode: ProgrammeCode) => (_evt: Event) => startProgrammeChange(programmeCode, true),
    });
  }, [
    showModal,
    startProgrammeChange,
    value.applicantId,
    guessedFeeCategory,
    value.choice,
    value.programmeCode,
    value.oldProgrammeCode,
    allProgrammesWithResults,
    permissions,
    schoolCode,
  ]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={10} widescreen={11} style={{ flexGrow: "1" }}>
            <FieldLabel label="Programme">
              <DropdownField<Opt<RequirementsCheckDTO>>
                value={programmeWithResults}
                readOnly={transitioning || readOnly || (programmeOptions.length === 1 && programme != null)}
                messages={messages}
                onChange={opt => {
                  if (opt != null && canMakeOffers(opt.programme, guessedFeeCategory, permissions)) {
                    handleStartProgrammeChange(opt.programme.code, false);
                  }
                }}
                onBlur={() => {
                  if (programme != null && programme.code !== initialProgramme?.code) {
                    const isClosed = isClosedToFeeCategory(programme, guessedFeeCategory);

                    const isFull = isFullToFeeCategory(programme, guessedFeeCategory);

                    if (isClosed || isFull) {
                      const title = isClosed ? "Programme closed" : "Programme full";

                      const content = `
                      We recommend against making offers on this programme.
                      It is ${spacesDescription(programme)}.
                    `;

                      showModal("alert", { title, content });
                    }
                  }
                }}
                placeholder="Select a programme"
                fluid
                search={programmeSearch}
                options={programmeOptions}
              />
            </FieldLabel>
          </Grid.Column>

          {showApplicationChoice && (
            <Grid.Column mobile={8} tablet={8} computer={6} widescreen={5} floated="right">
              <FieldLabel label="Choice">
                <DropdownField
                  value={value.choice}
                  readOnly={readOnly}
                  onChange={choice => onChange({ ...value, choice })}
                  fluid
                  options={ucasApplicationChoiceOptions}
                />
              </FieldLabel>
            </Grid.Column>
          )}
        </Grid.Row>
        {transitioning && (
          <CompactRow>
            <Grid.Column mobile={16} tablet={16} computer={10} widescreen={10} style={{ flexGrow: "1", paddingLeft: "2em" }}>
              Changing from{" "}
              {oldProgramme != null
                ? `${programmeCodeToUcasCourseCode(oldProgramme.code)} - ${oldProgramme.name}`
                : value.oldProgrammeCode != null
                  ? programmeCodeToUcasCourseCode(value.oldProgrammeCode)
                  : "???"}{" "}
            </Grid.Column>

            {!readOnly && (
              <Grid.Column mobile={8} tablet={8} computer={3} widescreen={3} floated="right">
                <Button fluid primary size="tiny" disabled={readOnly} onClick={props.completeProgrammeChange}>
                  Complete
                </Button>
              </Grid.Column>
            )}

            {!readOnly && (
              <Grid.Column mobile={8} tablet={8} computer={3} widescreen={3} floated="right">
                <Button fluid primary basic size="tiny" disabled={readOnly} onClick={props.cancelProgrammeChange}>
                  Revert
                </Button>
              </Grid.Column>
            )}
          </CompactRow>
        )}
      </Grid>

      {!readOnly && showInterSchoolChangeOfProgrammeButton && !transitioning && (
        <Button disabled={readOnly} onClick={showInterSchoolChangeOfProgramme} style={{ marginTop: ".5em" }}>
          Start inter-school Change of Programme
        </Button>
      )}

      {programme && (
        <ClosedProgrammeCheckView
          canMakeOffersOnClosedProgrammes={permissions.canMakeOffersOnClosedProgrammes(programme.schoolCode)}
          programme={programme}
          initialProgramme={initialProgramme}
          guessedFeeCategory={guessedFeeCategory}
          readOnly={readOnly}
        />
      )}

      {programme && (
        <RequirementsCheckView programmeCode={programme.code} requirements={programme.entryRequirements} qualifications={qualifications} />
      )}
    </>
  );
}

export default flowRight(withModal)(ProgrammeSelectionEditor);
