import React, { useContext } from "react";
import { Button, Container, Grid, Header, Icon } from "semantic-ui-react";
import { FeatureFlagsContext } from "@qmspringboard/app/src/FeatureFlagsProvider";
import { phoneCall } from "../api";
import * as toast from "./toast";

// Corresponds to applicant.details.telephone1 and 2:
type MaybeNumber = string | null | undefined;

interface ClickToCallProps {
  numbers: MaybeNumber[];
  applicantId: number;
}

function cleanNumber(number: MaybeNumber): string | null {
  const isPossibleNumber = number !== undefined && number !== null && number.trim().length > 0;
  if (!isPossibleNumber) {
    return null;
  }
  // Keep +, digits, and remove anything else and anything inside parentheses
  // E.g. +44 (01) 2345 6789 -> +4423456789
  return number
    .trim()
    .replace(/\([^)]*\)/g, "")
    .replace(/[^0-9+]/g, "");
}

function callButton(number: string, onClick: (number: string) => void) {
  return (
    <Button size="small" basic fluid onClick={() => onClick(number)}>
      <Icon name="phone" />
      {number}
    </Button>
  );
}

export default function ClickToCall(props: ClickToCallProps) {
  const { applicantId, numbers } = props;
  const featureFlags = useContext(FeatureFlagsContext);
  const makeCall = (number: string) => {
    phoneCall({ number, applicantId }).then(response => {
      if (response.success) {
        toast.success("Call requested", 5000);
      } else {
        const msg = response.message ? `Unable to place call - ${response.message}` : "Unable to place call";
        toast.error(msg, 5000);
      }
    });
  };

  const validNumbers: string[] = numbers
    .map(cleanNumber)
    .filter((n): n is string => n !== null)
    .filter(n => n.length > 0);

  return featureFlags.clickToCallEnabled && validNumbers.length > 0 ? (
    <Container>
      <div>
        <Header as="h3" dividing>
          Call
        </Header>
        <Grid columns="equal">
          {validNumbers.map(number => (
            <Grid.Row key={number}>
              <Grid.Column>{callButton(number, makeCall)}</Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </div>
    </Container>
  ) : null;
}
