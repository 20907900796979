import { Action as UploadAction, IState as UploadState, reducer as uploadReducer } from "@qmspringboard/shared/dist/reducers/uploads";
import { raise } from "@qmspringboard/shared/src/utils/raise";
import { default as PQueue } from "p-queue";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import * as api from "../../api";
import * as pot from "../../api/pot";
import useAppDispatch from "../../hooks/useAppDispatch";
import { AttachmentRequest, AttachmentType, DownloadLink, FileId, FileInfo } from "../../model/types";
import { Opt } from "../../utils";
import { AppDispatch, AppReducer } from "../actions";
import { rootSelector as applicantSelector } from "../applicantUpdate";
import { defaultErrorHandler, fetchWithMutex } from "../fetch";
import { AppState, GetState } from "../state";
import { MyAction } from "./actions";
import { initialState, MyState } from "./state";

const REQUEST_ATTACHMENTS = "applicantAttachment/REQUEST_ATTACHMENTS";
const SUCCESS_ATTACHMENTS = "applicantAttachment/SUCCESS_ATTACHMENTS";
const FAILURE_ATTACHMENTS = "applicantAttachment/FAILURE_ATTACHMENTS";

const REQUEST_REQUESTS = "applicantAttachment/REQUEST_REQUESTS";
const SUCCESS_REQUESTS = "applicantAttachment/SUCCESS_REQUESTS";
const FAILURE_REQUESTS = "applicantAttachment/FAILURE_REQUESTS";

const SUCCESS_DELETE = "applicantAttachment/SUCCESS_DELETE";
const SUCCESS_APPROVAL_STATECHANGE = "applicantAttachment/SUCCESS_APPROVAL_STATECHANGE";

const SUCCESS_FILE_REQUEST = "applicantAttachment/SUCCESS_FILE_REQUEST";

const UPLOAD_ADDED = "applicantAttachment/UPLOAD_ADDED";

const rootSelector: (state: AppState) => MyState = createSelector(applicantSelector, state => state.applicantAttachments);

const savedAttachments = createSelector(rootSelector, state => state.savedAttachments);

const localAttachments = createSelector(rootSelector, state => state.localAttachments);

const requestedAttachments = createSelector(rootSelector, state => state.requestedAttachments);

export const initialiseApplicantAttachments = (applicantId: number) =>
  fetchWithMutex<FileInfo[]>({
    mutex: "applicantAttachments",

    request(dispatch, getState) {
      return api.getAttachments(applicantId);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: REQUEST_ATTACHMENTS, requestId });
    },

    success(dispatch, getState, resource) {
      dispatch({ type: SUCCESS_ATTACHMENTS, resource });
    },

    error(dispatch, getState, error) {
      dispatch({ type: FAILURE_ATTACHMENTS, error });
    },
  });

export function initialiseAttachmentRequests(applicantId: number) {
  return fetchWithMutex<AttachmentRequest[]>({
    mutex: "applicantAttachmentRequests",

    request(dispatch, getState) {
      return api.getAttachmentRequests(applicantId);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: REQUEST_REQUESTS, requestId });
    },

    success(dispatch, getState, resource) {
      dispatch({ type: SUCCESS_REQUESTS, resource });
    },

    error(dispatch, getState, error) {
      dispatch({ type: FAILURE_REQUESTS, error });
    },
  });
}

function attachmentsReducer(state: MyState["savedAttachments"] = initialState.savedAttachments, action: MyAction): MyState["savedAttachments"] {
  switch (action.type) {
    case REQUEST_ATTACHMENTS:
      return pot.pending(action.requestId);
    case SUCCESS_ATTACHMENTS:
      return pot.ready(action.resource);
    case FAILURE_ATTACHMENTS:
      return pot.failed(action.error);

    case SUCCESS_DELETE:
      // append new request
      if (state.state === "ready") {
        return pot.ready(state.value.filter(file => file.fileId !== action.fileId));
      }
      return state;

    case SUCCESS_APPROVAL_STATECHANGE:
      if (state.state === "ready") {
        return pot.ready(
          state.value.map(file =>
            file.fileId === action.fileId
              ? {
                  ...file,
                  approvedBy: action.isApproved ? action.approvedBy : null,
                }
              : file,
          ),
        );
      }

      return state;

    // case REQUEST_FILE:
    //   return { ...state, savedAttachments: pot.pending(action.requestId) };

    default:
      return state;
  }
}

function requestReducer(
  state: MyState["requestedAttachments"] = initialState.requestedAttachments,
  action: MyAction,
): MyState["requestedAttachments"] {
  switch (action.type) {
    case REQUEST_REQUESTS:
      return pot.pending(action.requestId);
    case SUCCESS_REQUESTS:
      return pot.ready(action.resource);
    case FAILURE_REQUESTS:
      return pot.failed(action.error);
    case SUCCESS_FILE_REQUEST:
      // append new request
      if (state.state === "ready") {
        return pot.ready([...state.value, action.result]);
      }
      return state;

    default:
      return state;
  }
}

// TODO: "Unsafe" cast from React Hooks reducer to Redux reducer.
// The Redux reducer has an optional input state, the React one does not.
const widenedAttachmentUploadReducer = uploadReducer as AppReducer<UploadState>;

const reducer: AppReducer<MyState> = combineReducers({
  savedAttachments: attachmentsReducer,
  requestedAttachments: requestReducer,
  localAttachments: widenedAttachmentUploadReducer,
});

export default reducer;

const queue = new PQueue({ concurrency: 3 });

// TODO: parallel queue these changes
function attachmentApprove(fileId: FileId, isApproved: boolean, approvedBy: string) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      console.log("GO");
      await queue.add(() =>
        api.attachmentApprove({
          fileId,
          isApproved,
        }),
      );
    } catch (err) {
      defaultErrorHandler(dispatch, getState, err);
    }
    dispatch({
      type: SUCCESS_APPROVAL_STATECHANGE,
      fileId,
      isApproved,
      approvedBy,
    });
  };
}

function attachmentDownload(fileId: FileId) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<DownloadLink> => {
    try {
      return await api.attachmentDownloadUrl(fileId);
    } catch (err) {
      defaultErrorHandler(dispatch, getState, err);
      return raise(err);
    }
  };
}

function attachmentRequest(fileType: AttachmentType, applicantId: number, message?: string | null) {
  return fetchWithMutex<AttachmentRequest>({
    mutex: "attachments",

    request(dispatch, getState) {
      return api.attachmentRequest({
        applicantId,
        fileType,
        message,
      });
    },

    success(dispatch, getState, result) {
      dispatch({ type: SUCCESS_FILE_REQUEST, result });
    },
  });
}

function attachmentUpload(applicantId: number, files: File[], type: AttachmentType) {
  return async (dispatch: AppDispatch) => {
    return Promise.all(
      Array.from(files).map(file => {
        dispatch({ type: UploadAction.UPLOADING, file });
        return api.uploadFile(applicantId, file, type).then(
          token => {
            dispatch({ type: UploadAction.COMPLETE, file, token });
          },
          () => {
            dispatch({ type: UploadAction.ERROR, file });
          },
        );
      }),
    );
  };
}

function attachmentDelete(fileId: FileId) {
  return async (dispatch: AppDispatch) => {
    try {
      const result = api.attachmentDelete(fileId);
      dispatch({ type: SUCCESS_DELETE, fileId });
      console.info({ result });
    } catch (e) {
      // FIXME: Do something.
    }
  };
}

export function useAttachments() {
  const dispatch = useAppDispatch();

  return {
    attachmentPot: useSelector(savedAttachments),
    attachmentRequestsPot: useSelector(requestedAttachments),
    attachmentUploadData: useSelector(localAttachments),

    fetchAttachments: useCallback(
      (applicantId: number) =>
        Promise.all([dispatch(initialiseAttachmentRequests(applicantId)), dispatch(initialiseApplicantAttachments(applicantId))]),
      [dispatch],
    ),

    attachmentDelete: useCallback((fileId: FileId) => dispatch(attachmentDelete(fileId)), [dispatch]),

    attachmentStartUpload: useCallback(() => dispatch({ type: UPLOAD_ADDED }), [dispatch]),

    setUploadNew: useCallback((files: File[]) => dispatch({ type: UploadAction.NEW, files }), [dispatch]),

    setUploadPending: useCallback((files: File[]) => dispatch({ type: UploadAction.PENDING, files }), [dispatch]),

    removeUpload: useCallback((file: File) => dispatch({ type: UploadAction.REMOVE, file }), [dispatch]),

    setNewUploadsToPending: useCallback(() => dispatch({ type: UploadAction.SET_NEW_TO_PENDING }), [dispatch]),

    setPendingUploadsToNew: useCallback(() => dispatch({ type: UploadAction.SET_PENDING_TO_NEW }), [dispatch]),

    resetUploads: useCallback(() => dispatch({ type: UploadAction.RESET }), [dispatch]),

    attachmentApprove: useCallback(
      (fileId: FileId, isApproved: boolean, username: string) => dispatch(attachmentApprove(fileId, isApproved, username)),
      [dispatch],
    ),

    attachmentDownload: useCallback(
      async (fileId: FileId) => {
        const link: DownloadLink = await dispatch(attachmentDownload(fileId));
        window.location.href = link.url;
      },
      [dispatch],
    ),

    attachmentRequest: useCallback(
      (fileType: AttachmentType, applicantId: number, message: Opt<string>) => dispatch(attachmentRequest(fileType, applicantId, message)),
      [dispatch],
    ),

    uploadAttachments: useCallback(
      (applicantId: number, files: File[], type: AttachmentType) => dispatch(attachmentUpload(applicantId, files, type)),
      [dispatch],
    ),
  };
}
