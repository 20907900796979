import { push } from "connected-react-router";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { UnknownClientError } from "../../errors";
import { labelPrototypeRule } from "../../model/labelPrototype";
import { Checked, LabelPrototype, Message } from "../../model/types";
import { AppReducer } from "../actions";
import { fetchWithMutex, validationErrorHandler } from "../fetch";
import { AppState } from "../state";
import * as teams from "../teams";
import { emptyLabelPrototype, initialState, MyState } from "./state";

///////////////
// Selectors //
///////////////

export const myState = (state: AppState): MyState => state.route.labelPrototypeUpdate;

export const proto = (state: AppState): LabelPrototype => myState(state).proto;

export const messages = (state: AppState): Message[] => myState(state).clientMessages.concat(myState(state).serverMessages);

export const fetching = (state: AppState): boolean => myState(state).fetching;

export const fetched = (state: AppState): boolean => myState(state).fetched;

/////////////
// Actions //
/////////////

const INITIALISE = "labelPrototypeUpdate/INITIALISE";
const LOAD_START = "labelPrototypeUpdate/LOAD_START";
const LOAD_SUCCESS = "labelPrototypeUpdate/LOAD_SUCCESS";
const UPDATE = "labelPrototypeUpdate/UPDATE";
const SAVE_START = "labelPrototypeUpdate/SAVE_START";
const SAVE_SUCCESS = "labelPrototypeUpdate/SAVE_SUCCESS";
const DELETE_START = "labelPrototypeUpdate/DELETE_START";
const DELETE_SUCCESS = "labelPrototypeUpdate/DELETE_SUCCESS";
const SET_SERVER_MESSAGES = "labelPrototypeUpdate/SET_SERVER_MESSAGES";

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState(), action) => {
  function clearServerMessages(state: MyState): MyState {
    return { ...state, serverMessages: [] };
  }

  function commitChanges(state: MyState) {
    return { ...state, originalGroup: state.proto };
  }

  function updateAndValidate(state: MyState, proto: LabelPrototype) {
    return { ...state, proto, clientMessages: labelPrototypeRule(proto) };
  }

  switch (action.type) {
    case INITIALISE:
      return commitChanges(updateAndValidate({ ...state, fetching: false, serverMessages: [] }, emptyLabelPrototype(action.schoolCode)));
    case LOAD_START:
      return { ...state, fetching: true };
    case LOAD_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.proto));
    case UPDATE:
      return clearServerMessages(updateAndValidate(state, action.proto));
    case SAVE_START:
      return { ...state, fetching: true };
    case SAVE_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.proto));
    case SET_SERVER_MESSAGES:
      return { ...state, fetching: false, serverMessages: action.messages };
    default:
      return state;
  }
};

export default reducer;

/////////////////////
// Action Creators //
/////////////////////

export const initialise = () => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: INITIALISE,
      schoolCode: teams.currentSchoolCode(getState()),
    });
  };
};

export function load(id: number) {
  return fetchWithMutex<LabelPrototype>({
    mutex: "labelPrototypeLoad",

    request(dispatch, getState) {
      return api.fetchLabelPrototype(id);
    },

    pending(dispatch, getState) {
      dispatch({ type: LOAD_START });
    },

    success(dispatch, getState, proto) {
      dispatch({ type: LOAD_SUCCESS, proto });
    },
  });
}

export const update = (proto: LabelPrototype) => ({ type: UPDATE, proto });

export function cancel() {
  return (dispatch: Function) => {
    dispatch(push("/admin/label"));
  };
}

export function save(proto: LabelPrototype) {
  return fetchWithMutex<Checked<LabelPrototype>>({
    mutex: "labelPrototypeSave",

    request(dispatch, getState) {
      return proto.id >= 0 ? api.updateLabelPrototype(proto) : api.insertLabelPrototype(proto);
    },

    pending(dispatch, getState) {
      dispatch({ type: SAVE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, getState, checked) {
      checked.data && toast.success("Label saved");
      checked.data && dispatch({ type: SAVE_SUCCESS, proto: checked.data });
      checked.messages && dispatch({ type: SET_SERVER_MESSAGES, messages: checked.messages });
      checked.data && dispatch(push("/admin/label"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}

export function del(proto: LabelPrototype) {
  return fetchWithMutex({
    mutex: "labelPrototypeDelete",

    request(dispatch, getState) {
      return proto.id >= 0 ? api.deleteLabelPrototype(proto.id) : Promise.reject(new UnknownClientError("Cannot delete an unsaved label"));
    },

    pending(dispatch, getState) {
      dispatch({ type: DELETE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, getState, _result) {
      toast.success("Label deleted");
      dispatch({ type: DELETE_SUCCESS });
      dispatch(push("/admin/label"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}
